import { Button, Flex, FormLabel, Icon, useColorModeValue, Spinner, Box, Text } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets

import "./dashcalendar.css"

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';


import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useEffect, useRef, useState } from 'react';
import { DashCalEvent, newDashCalEvent, Trade } from 'views/trader/trades/variables/types';
import { currencyformat } from 'views/trader/trades/variables/FormatData';
import DashCalendarModal from 'views/trader/dashboard/components/calendar/DashCalendarModal2';
import { useData } from 'services/data/DataProvider';
import { useLoading } from 'services/loading/LoadingProvider';

export default function DashCalendar() {
    // Chakra Color Mode
    dayjs.extend(isBetween);
    dayjs.extend(weekday);
    const { loading } = useLoading();
    const { periodtrades, defaultcurrency } = useData();

    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const calRef = useRef(null);
    const [calTitle, setCalTitle] = useState("");
    const [monthIndex, setMonthIndex] = useState(0);
    const [monthStats, setMonthStats] = useState<{ month: string, nett: number, rvalue: number }[]>([]);

    const [calData, setCalData] = useState([]);
    const [hidedays, setHideDays] = useState([0]);

    useEffect(() => {
        fetchData();
    }, [periodtrades, bdColor])

    const fetchData = async () => {
        await updateEvents();
        sethiddendays();
    }
    const updateEvents = () => {

        return new Promise(async (resolve) => {

            if (!periodtrades.length) {
                try { // need catch incase getApi() null
                    //remove existing events from calendar
                    let evts = calRef.current.getApi().getEvents();
                    for (let ev of evts) {
                        ev.remove();
                    }
                }
                catch (e) { }
            }


            calData.length = 0; //.splice(0, calData.length);

            const arr: { date: string, trades: Trade[] }[] = [];
            const weekarr: { week: number, trades: Trade[] }[] = [];
            const montharr: { month: string, trades: Trade[] }[] = [];
            //collect trades into date object arrays
            for (let tr of periodtrades) {

                let week = dayjs(tr.datetime).week();
                let month = dayjs(tr.datetime).format('MMMM YYYY');


                let i = arr.findIndex((a) => a.date === dayjs(tr.datetime).format('YYYY-MM-DD'))
                let wi = weekarr.findIndex((w) => w.week === week);
                let mi = montharr.findIndex((w) => w.month === month);

                if (i < 0) {
                    //let ar = [tr];
                    arr.push({ date: dayjs(tr.datetime).format('YYYY-MM-DD'), trades: [tr] });
                }
                else {
                    arr[i].trades.push(tr);
                }

                if (wi < 0) {
                    weekarr.push({ week: week, trades: [tr] })
                }
                else {
                    weekarr[wi].trades.push(tr);
                }

                if (mi < 0) {
                    montharr.push({ month: month, trades: [tr] })
                }
                else {
                    montharr[mi].trades.push(tr);
                }
            }

            let monthstats: { month: string, nett: number, rvalue: number }[] = [];

            for (let m of montharr) {

                let mnett = 0;
                let rvaluestotal: number = 0;
                let rvaluetrades = 0;
                for (let t of m.trades) {

                    let ex = (t.currency !== defaultcurrency ? t.exchange : 1);
                    let nett = (t.nett / ex);
                    mnett += nett;

                    if (t.rvalue !== 0) {
                        rvaluestotal += t.rvalue;
                        rvaluetrades++;
                    }
                }
                monthstats.push({ month: m.month, nett: mnett, rvalue: (rvaluetrades > 0 ? (rvaluestotal / rvaluetrades) : 0) })
            }

            setMonthStats(monthstats);


            // generate calendar events
            const arr2: DashCalEvent[] = [];
            for (let ev of arr) {

                let evt: DashCalEvent = newDashCalEvent();
                evt.start = ev.date;
                evt.end = dayjs(ev.date).add(1, 'day').format('YYYY-MM-DD');

                let total = 0;
                let riskreward = 0;
                let rrtrades = 0;

                for (let t of ev.trades) {

                    if (t.stop_price > 0) {
                        riskreward += ((t.points / t.size) / Math.abs(t.entry_avg - t.stop_price))
                        rrtrades++;
                    }

                    let ex = t.currency !== defaultcurrency ? t.exchange : 1;
                    total += (t.nett / ex);
                    evt.extendedProps.trades.push(t.tradeid);
                    evt.extendedProps.groupid = t.groupid;
                }

                evt.extendedProps.avgR = (rrtrades > 0 ? riskreward / rrtrades : 0);
                evt.extendedProps.week = 0;

                evt.backgroundColor = (total > 0 ? '#01B574' : '#EE5D50');
                evt.title = currencyformat(total, defaultcurrency);
                arr2.push(evt);
            }
            /// week summaries
            for (let ev of weekarr) {
                let sat = dayjs(ev.trades[0].datetime).day(6);

                let evt: DashCalEvent = newDashCalEvent();
                evt.start = sat.format('YYYY-MM-DD');
                let total = 0;
                let riskreward = 0;
                let rrtrades = 0;

                for (let t of ev.trades) {

                    if (t.stop_price > 0) {
                        riskreward += ((t.points / t.size) / Math.abs(t.entry_avg - t.stop_price))
                        rrtrades++;
                    }

                    let ex = t.currency !== defaultcurrency ? t.exchange : 1;
                    total += (t.nett / ex);
                }

                evt.extendedProps.tradecount = ev.trades.length;
                evt.extendedProps.avgR = (rrtrades > 0 ? riskreward / rrtrades : 0);
                evt.extendedProps.week = ev.week;
                evt.backgroundColor = localStorage.getItem('chakra-ui-color-mode') === 'dark' ? '#0b1437' : '#ffffff'
                evt.borderColor = (total > 0 ? '#01B574' : '#EE5D50');
                evt.textColor = (total > 0 ? '#01B574' : '#EE5D50');
                evt.title = currencyformat(total, defaultcurrency);
                arr2.push(evt);

            }

            if (periodtrades.length) {
                calRef.current.getApi().gotoDate(dayjs(periodtrades[periodtrades.length - 1].datetime).format("YYYY-MM-DD"));
            }
            else {
                calRef.current.getApi().gotoDate(dayjs().format("YYYY-MM-DD"));
            }

            setTitleBar(monthstats);

            setCalData([...calData, ...arr2]);

            resolve('');
        });
    }

    const setTitleBar = (monthstats: any) => {
        setCalTitle(calRef.current.getApi().view.title);
        let mindex = monthstats.findIndex((m: { month: string; }) => m.month === dayjs(calRef.current.getApi().view.currentStart).format('MMMM YYYY'))
        setMonthIndex(mindex);
    }


    const sethiddendays = () => {
        const start = calRef.current.getApi().view.currentStart;
        const end = calRef.current.getApi().view.currentEnd;
        const eventsInView = calRef.current.getApi().getEvents().filter((event: { start: number; }) => {
            return event.start >= start && event.start < end;
        });
        let sunday = false;
        for (let i = 0; i < eventsInView.length; i++) {
            let ev = eventsInView[i];
            let day = dayjs(ev.start).get('day');
            if (day === 0) {
                sunday = true;
                break;
            }
        }
        setHideDays(sunday ? [] : [0])
    }

    const calforward = () => {
        calRef.current.getApi().next();
        setTitleBar(monthStats);
        sethiddendays();
    }
    const calback = () => {
        calRef.current.getApi().prev();
        setTitleBar(monthStats);
        sethiddendays();
    }
    const lastTrade = () => {
        try {
            let evts = calRef.current.getApi().getEvents();
            calRef.current.getApi().gotoDate(evts[evts.length - 1].start);//data.length ? data[data.length - 1].date[0] : dayjs().format("YYYY-MM-DD"))
        }
        catch (err) { }
        setTitleBar(monthStats);
        sethiddendays();
    }
    function renderEventContent(ev: any) {
        if (ev.event.extendedProps.week) {
            return (
                <Box as={Button} height={'auto'} bg={ev.event.backgroundColor} w='100%' borderRadius={'5px'} p={'10px'} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                    <Flex direction={'column'} justify={'space-evenly'} h='100%'>
                        <Text fontSize={{ base: 'xs', md: 'xs' }} color={ev.event.textColor}>Week {ev.event.extendedProps.week}</Text>
                        <Text fontSize={{ base: 'xs', md: 'md' }} color={ev.event.textColor} mt='5px'>{ev.event.title}</Text>
                        <Text fontSize={'xs'} color={ev.event.textColor} mt='5px'>
                            {ev.event.extendedProps.tradecount} {ev.event.extendedProps.tradecount > 1 ? 'trades' : 'trade'}
                        </Text>
                    </Flex>
                </Box>
            )
        }
        else {
            return (
                <DashCalendarModal event={ev} />
            )
        }

    }

    return (
        <Card h='100%' minH={'800px'} p='20px' borderColor={bdColor} borderWidth='1px' bg='transparent'>
            <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} mb='20px'>
                <Flex direction={'row'} mt='5px'>
                    <FormLabel fontWeight='bold' fontSize='md' mb='8px'>{calTitle}</FormLabel>
                    {/*<FormLabel fontWeight='normal' fontSize='md' mb='8px' color='secondaryGray.600'>{calTitle}</FormLabel>*/}
                </Flex>

                {(monthStats[monthIndex] && !loading) &&
                    <Box borderWidth={'2px'} borderRadius={'15px'} borderColor={monthStats[monthIndex].nett < 0 ? 'red.500' : 'green.500'} mb={{ base: '10px', md: '0px' }}>
                        <Flex textAlign={'center'} h='100%' w='100%' align={'center'}>
                            <Text ms='20px' me='20px' fontWeight='bold' color={monthStats[monthIndex].nett < 0 ? 'red.500' : 'green.500'}>{currencyformat(monthStats[monthIndex].nett, defaultcurrency) + "   (avg " + monthStats[monthIndex].rvalue.toFixed(2) + " R)"}</Text>
                        </Flex>
                    </Box>
                }

                <Flex direction={{ base: 'column', md: 'row' }}>
                    {/*<Flex>
                        <FormControl display='flex' alignItems='center' ms='10px' mb={{ base: '10px', md: '0px' }}>
                            <Switch id='weekends' onChange={updateWeekends} isChecked={showweekends} />
                            <FormLabel htmlFor='weekends' mb='0' ms='10px'>
                                Weekends
                            </FormLabel>
                        </FormControl>
                    </Flex>*/}
                    <Flex direction={'row'}>
                        <Button variant={'outline'} size={'md'} borderRadius='5px' me='5px' onClick={lastTrade}>
                            Last
                        </Button>
                        <Button variant={'outline'} p='0px' size={'md'} borderRadius='5px' onClick={calback}>
                            <Icon as={MdChevronLeft} boxSize={5} />
                        </Button>
                        <Button variant={'outline'} p='0px' size={'md'} borderRadius='5px' ms='5px' onClick={calforward}>
                            <Icon as={MdChevronRight} boxSize={5} />
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            <Flex h='100%' align={'center'} justify={'center'} hidden={!loading}>
                <Spinner size={'lg'} thickness='4px' speed='0.65s' color={spinnerColor}></Spinner>
            </Flex>
            <Box h='100%' w='100%' hidden={loading}>
                <FullCalendar
                    ref={calRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={false}
                    initialView='dayGridMonth'
                    dayCellClassNames={(info) => {
                        if (info.date.getMonth() !== info.view.currentStart.getMonth()) {
                            return 'non-current-month'; // Add a custom class to non-current month days
                        }
                        return '';
                    }}
                    dayHeaderContent={(arg) => {
                        const fullDayName = arg.date.toLocaleString('en-us', { weekday: 'short' });
                        return (fullDayName === 'Sat' ? "Week" : fullDayName)
                    }}
                    fixedWeekCount={false}
                    contentHeight='100%'
                    events={calData}
                    initialDate={dayjs().format('YYYY-MM-DD')}
                    editable={false}
                    height='100%'
                    hiddenDays={hidedays}
                    eventContent={renderEventContent}
                />
            </Box>
        </Card>
    );
}