import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Text, useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"

export const TradeLogicClear = (props: { callbackfunction: any}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const confirmClear = () => {
        onClose();
        props.callbackfunction();
    }

    return (
        <>
            <Button colorScheme='red' variant={'outline'} mr={3} onClick={onOpen}>
                Clear
            </Button>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Clear trade logic?
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text fontWeight={'700'}>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button variant={'ghost'} ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={confirmClear} ml={3}>
                                Clear
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}