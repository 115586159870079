import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, Button, Checkbox, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useColorModeValue, FormLabel } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";
import { db } from "services/db";
import useNewInstrument from "./useNewInstrument";

export const useStoplossDialog = ({ }: any): [(t: string) => Promise<number>, () => JSX.Element] => {

    const [promise, setPromise] = useState<{ resolve: (value: number) => void } | null>(null);

    const [instrument, setInstrument] = useState(useNewInstrument());
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const [defChecked, setDefChecked] = useState(true);
    const [loading, setLoading] = useState(false);

    const cancelRef = useRef()

    async function updateStop(t: string): Promise<number> {
        return new Promise(async (resolve, reject) => {

            db.instruments.get({ ticker: t }).then((i) => {

                setInstrument(i);

            }).catch(() => { })

            setPromise({ resolve });
        });
    }

    const handleConfirm = () => {
        setLoading(true);
        if (defChecked) {
            db.instruments.put(instrument, instrument.id);
        }
        promise?.resolve(instrument.defaultstop ? instrument.defaultstop : 0);
        handleClose();
    }

    const handleClose = () => {
        setPromise(null);
        setLoading(false);
    }

    const updateDefault = (e: ChangeEvent<HTMLInputElement>) => {
        setDefChecked(e.target.checked);
    }

    const updateStoploss = (valueAsString: string, valueAsNumber: number) => {
        instrument.defaultstop = valueAsNumber;
    }

    const StoplossDialog = () => {
        return (
            <>
                <AlertDialog leastDestructiveRef={cancelRef} isOpen={promise !== null} onClose={handleConfirm} motionPreset='slideInBottom' closeOnOverlayClick={false}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                <Flex direction={'column'} align={'center'}>
                                    <Text fontWeight={'700'} mb='20px'>STOP LOSS <ArrowForwardIcon /> default</Text>
                                    <Text fontWeight={'700'} color={'red.500'}>{instrument.description}</Text>
                                </Flex>
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                <Flex justify='center'>
                                    <Text fontWeight={'500'} mb='20px' me='5px'>
                                        Do you want to use a default stoploss for {instrument.ticker}?
                                    </Text>
                                </Flex>
                                <Flex justify='center'>
                                    <Text fontWeight={'700'} size={'xs'} mb='20px' color={'gray.400'}>
                                        (or you can enter them manually later)
                                    </Text>
                                </Flex>
                                <Flex justify='center' mb='10px'>
                                    <FormLabel fontWeight='bold' fontSize='sm' mt='5px'>ENTER POINTS</FormLabel>
                                </Flex>
                                <Flex justify='center' mt='10px' mb='10px'>
                                    <NumberInput min={0} defaultValue={instrument.defaultstop} allowMouseWheel={true} onChange={updateStoploss} step={instrument.ticksize}>
                                        <NumberInputField color={textColor} placeholder="0.00" />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Flex>
                                <Flex direction={'row'} justify={'space-between'} mt='30px' mb='20px'>
                                    <Checkbox isChecked={defChecked} onChange={(e) => updateDefault(e)}>Save as default?</Checkbox>
                                    <Flex>
                                        <Button ref={cancelRef} colorScheme='teal' onClick={handleConfirm} ml={3} isLoading={loading} loadingText="Processing">
                                            Accept
                                        </Button>
                                    </Flex>
                                </Flex>
                            </AlertDialogBody>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        )
    };

    return [updateStop, StoplossDialog];
}