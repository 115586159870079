/* eslint-disable */

import {
	Badge, Flex, Table, Tbody, Td, Text,
	Th, Thead, Tr, useColorModeValue, Divider, IconButton,
	NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
	Select,
	Button,
	Icon,
	useToast,
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	SortingState,
	PaginationState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table';
// Custom components  
//import TradeModal from '../modals/TradeModal';
import { Mentee, Mentor, Trade } from 'views/trader/trades/variables/types';
import { useEffect, useRef, useState } from 'react';
import { getDuration, currencyformat, numberColor } from 'views/trader/trades/variables/FormatData';
import { MdAdd, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import RuleScore from 'views/trader/trades/components/fields/RuleScore';
import dayjs from 'dayjs';
import MentorTradeDrawer from './MentorTradeDrawer2';
import api from 'services/api';
import { useData } from 'services/data/DataProvider';


const columnHelper = createColumnHelper<Trade>();

export default function MentorTradesTable(props: { trades: Trade[], mentee: Mentee, mentor: Mentor, updatePending:any }) {

	const { trades, mentee, mentor } = props;
	const {dateformat} = useData();

	const [data, setData] = useState<Trade[]>([]);
	const [loading, setLoading] = useState(false);
	const [loadingtradeid, setLoadingtradeid] = useState(0);

	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [tablesize, setTablesize] = useState(localStorage.getItem('tablesize') ? Number(localStorage.getItem('tablesize')) : 10);
	const tradeDrawerRef = useRef(null);

	const toast = useToast();

	useEffect(() => {
		setData(trades);
	}, [trades, mentee, mentor])

	const showTrade = async (trade: Trade) => {
		setLoadingtradeid(trade.tradeid);
		setLoading(true);
		if(mentee){
			await api.post('/mentor/getstudenttrade', { trade: trade }).then(async (res) => {
				if (res.data.length) {
					tradeDrawerRef.current.openModal(res.data[0]);
				}
				else {
					toast({ title: 'Unable to retrieve trade ...', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
				}
				setLoading(false);
			}).catch((e) => {
				toast({ title: 'Unable to retrieve trade ...', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
				setLoading(false);
			})
		}
		if(mentor){
			await api.post('/mentor/getmentortrade', { trade: trade }).then(async (res) => {
				if (res.data.length) {
					tradeDrawerRef.current.openModal(res.data[0]);
				}
				else {
					toast({ title: 'Unable to retrieve trade ...', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
				}
				setLoading(false);
			}).catch((e) => {
				toast({ title: 'Unable to retrieve trade ...', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
				setLoading(false);
			})
		}
		
	}

	const savetrade = async (trade: Trade) => {
		if (mentee !== null) {
			let tr = data.slice();
			let index = tr.findIndex((t) => t.tradeid === trade.tradeid);
			let reviewed = false;
			if (index > -1) {
				reviewed = tr[index].reviewed;
				tr[index].reviewed = true;
			}
			setData(tr);
			if(props.updatePending){
				props.updatePending(tr[index].userid, reviewed);
			}
		}
		
	}

	const columns = [
		columnHelper.accessor('tradeid', {
			id: 'tradeid',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
				</Text>
			),
			cell: (info) => (
				<>
					{info.row.original.reviewed ?
						(
							<Button variant='outline' colorScheme={'green'} size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original)} isLoading={loading && info.row.original.tradeid === loadingtradeid} isDisabled={loading && info.row.original.tradeid !== loadingtradeid}>
								Reviewed +
							</Button>
						) : (
							<Button variant='outline' size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original)} isLoading={loading && info.row.original.tradeid === loadingtradeid} isDisabled={loading && info.row.original.tradeid !== loadingtradeid}>
								<Icon color='secondaryGray.500' as={MdAdd} w='20px' h='20px' />
							</Button>
						)}
				</>
			)
		}),
		columnHelper.accessor('datetime', {
			id: 'date',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DATE
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Flex direction='column'>
						<Text color={textColor} fontSize='sm' fontWeight='700'>
							{dayjs(info.getValue()).format(dateformat)}
						</Text>
						<Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
							{"Duration: " + getDuration(info.row.original.duration)}
						</Text>
					</Flex>
				</Flex>
			)
		}),
		columnHelper.accessor('ticker', {
			id: 'ticker',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TICKER
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('side', {
			id: 'side',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIDE
				</Text>
			),
			cell: (info) => (

				<Badge
					colorScheme={info.getValue().toLocaleLowerCase() === "sell" ? 'red' : 'green'}
					color={info.getValue().toLocaleLowerCase() === "sell" ? 'red.500' : 'green.500'}
					fontSize='sm'
					fontWeight='600'>
					{info.getValue().toUpperCase()}
				</Badge>
			)
		}),
		columnHelper.accessor('size', {
			id: 'size',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIZE
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('entry_avg', {
			id: 'entry',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ENTRY
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600' >
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('exit_avg', {
			id: 'exit',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					EXIT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('points', {
			id: 'points',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					POINTS
				</Text>
			),
			cell: (info) => (
				<Text color={numberColor(info.getValue())} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('profitloss', {
			id: 'profitloss',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					P/L
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('fees', {
			id: 'fees',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					FEES
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('nett', {
			id: 'nett',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NETT
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('rvalue', {
			id: 'rvalue',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					R-VALUE
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={(getValue() !== 0 ? (getValue() > 0 ? (getValue() < 1 ? 'orange.500' : 'green.500') : 'red.500') : textColorSecondary)} fontSize='sm' fontWeight='600'>
					{getValue() !== 0 ? getValue().toFixed(2) : 'No stop'}
				</Text>
			)
		}),
		columnHelper.accessor('rulescore', {
			id: 'rulescore',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.500'>
					RULE Score
				</Text>
			),
			cell: (info) => (
				<RuleScore rulescore={info.getValue()} width={'80%'} size={'lg'} />
			)
		})
	];

	const updatePageSize = (size: number) => {
		setTablesize(size);
		localStorage.setItem('tablesize', size.toFixed());
		table.setPageSize(size);
	}

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: tablesize,
	})
	const [sorting, setSorting] = useState<SortingState>([{
		id: 'date',
		desc: true, // sort by name in descending order by default
	}]);
	const [columnVisibility, setColumnVisibility] = useState({});

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			pagination,
			columnVisibility
		},
		onColumnVisibilityChange: setColumnVisibility,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,

		debugTable: true
	});


	return (
		<>
			<Table variant='simple' color='gray.500' mt="12px">
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {

								return (
									<Th
										key={header.id}
										colSpan={header.colSpan}
										pe='10px'
										borderColor={borderColor}
										cursor='pointer'
										onClick={header.column.getToggleSortingHandler()}>
										<Flex
											justifyContent='space-between'
											align='center'
											fontSize={{ sm: '10px', lg: '12px' }}
											color='gray.400'>
											{flexRender(header.column.columnDef.header, header.getContext())}{{
												asc: '',
												desc: '',
											}[header.column.getIsSorted() as string] ?? null}
										</Flex>
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => {
						return (
							<Tr key={row.id}>
								{row.getVisibleCells().map((cell) => {
									return (
										<Td
											key={cell.id}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Divider mt='10px' hidden={table.getRowCount() <= 10} />
			<Flex direction={{ base: 'column', md: 'row' }} justify='center' hidden={table.getRowCount() <= 10}>
				<Flex direction='row' justify='center' hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
					<Text mt='23px' ms='20px' me='5px'>Page</Text>
					<Text as='b' mt='23px' ms='5px' me='20px'>{table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount().toLocaleString()}</Text>
					<Text mt='23px' color={textColorSecondary}>Go to page: </Text>
					<NumberInput ms='10px' mt='20px' size='sm' min={1} max={table.getPageCount()} w='85px' allowMouseWheel={true} hidden={table.getRowCount() <= table.getState().pagination.pageSize}
						onChange={(valueString) => table.setPageIndex(valueString ? Number(valueString) - 1 : 0)}
					>
						<NumberInputField color={textColor} borderRadius='5px' />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				</Flex>
				<Flex direction='row' justify='center'>
					<Select size='sm' mt='20px' ms='10px' me='10px' borderRadius='5px' alignContent='end' w='120px' value={tablesize}//table.getState().pagination.pageSize}
						onChange={e => { updatePageSize(Number(e.target.value)) }}
					>
						{[10, 20, 30, 40, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</Select>
					<Flex hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowLeft />} onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowLeft />} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowRight />} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowRight />} onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
					</Flex>
				</Flex>
			</Flex>
			<MentorTradeDrawer ref={tradeDrawerRef} completereview={savetrade} isStudent={mentor !== null}></MentorTradeDrawer>
		</>
	);

}
