import { Trade, UserTradeLogic } from "views/trader/trades/variables/types";
import api from "./api";
import { setDefaultAccount, setDefaultGroup, setGroupAccountsSyncDue, setGroupsSyncDue } from "./dbservice";

export async function updateTrade(trade: Trade): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post('/trade/updatetrade', trade)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(true); // ie sync_due = true;
            })
    })
}

export async function updateUserTradeLogic(logic: UserTradeLogic): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post('/trade/updatetradeslogic', logic)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(false); // ie sync_due = true;
            })
    })
}

export async function updatesubscription(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post("/subs/update", payload)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(false);
            });
    })
}

export async function updateDefaultGroup(grpid: number, userid: number, muuid: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await setDefaultGroup(grpid, userid).then(async () => {
            await api.post('/accounts/defaultgroup', { groupid: grpid, uuid: muuid })
                .then(async (res) => {
                    if (res === undefined) {
                        await setGroupsSyncDue(true);
                    }
                })
                .catch(async (error) => {
                    await setGroupsSyncDue(true);
                });
        }).catch(() => { });

        resolve('');
    })
}
export async function updateDefaultAccount(accountid: number, grpid: number, userid: number, muuid: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await setDefaultAccount(accountid, grpid, userid).then(async (accid) => {
            await api.post('/accounts/defaultaccount', { accountid: accountid, groupid: grpid, uuid: muuid })
              .then(async (res) => {
                if (res.data === false) {
                  await setGroupAccountsSyncDue(grpid, true);
                }
              })
              .catch(async (error) => {
                await setGroupAccountsSyncDue(grpid, true);
              });
          }).catch(() => { });
          resolve('');
    })
}


