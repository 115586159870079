import { Flex, Heading, useColorModeValue, Text, ListItem, UnorderedList, Link as ChakraLink, useMediaQuery } from '@chakra-ui/react'
import LandingOtherLayout from 'layouts/landing/variants/LandingOther'
import { Link as ReactRouterLink } from 'react-router-dom';


const Terms = () => {

  const textColor = useColorModeValue('gray.700', 'gray.200');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  return (
    <LandingOtherLayout parent='terms'>
      <></>
      <Flex position={'relative'} align="center" maxW={{ xl: "1200px" }} px={isLargerThanMD ? '16' : '10'} justify={{ base: "center", md: "space-around", xl: "space-between" }} direction={'column'}>
        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='50px' size="lg" fontWeight="bold" color={textColor}>Terms of Use</Heading>
          <Heading mb='50px' size="md" fontWeight="bold" color={textColor}>Updated at 2024-09-30</Heading>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>General Terms</Heading>
          <Text mb='10px'>These Terms & Conditions are a contract between you and TradeJorno (referred to in these Terms & Conditions as "TradeJorno", "us", "we" or "our"), the provider of the TradeJorno website and the services accessible from the TradeJorno website (which are collectively referred to in these Terms & Conditions as the "TradeJorno Service"). You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions, please do not use the TradeJorno Service. In these Terms & Conditions, "you" refers both to you as an individual and to the entity you represent. If you violate any of these Terms & Conditions, we reserve the right to cancel your account or block access to your account without notice.</Text>
          <Text mb='10px'>By accessing, creating an account or buying a subscription with TradeJorno, you confirm that you are in agreement with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and TradeJorno. Under no circumstances shall TradeJorno team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if TradeJorno team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof. TradeJorno will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy in any moment.</Text>
          <Text mb='10px'>The Company Sites and Materials are not intended for or directed towards children under 18 years of age. By accessing or using the Company Sites and Materials, you affirm that you are over the age of 18.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>General Disclaimer</Heading>
          <Text mb='10px'>TradeJorno is not responsible for any content, code or any other imprecision.</Text>
          <Text mb='10px'>TradeJorno does not provide warranties or guarantees.</Text>
          <Text mb='10px'>In no event shall TradeJorno be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</Text>
          <Text mb='10px'>The TradeJorno Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. TradeJorno is a distributor and not a publisher of the content supplied by third parties; as such, TradeJorno exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the TradeJorno Service. This includes any performance advice or guidance provided in any capacity by mentors to traders using the service. Without limiting the foregoing, TradeJorno specifically disclaims all warranties and representations in any content transmitted on or in connection with the TradeJorno Service or on sites that may appear as links on the TradeJorno Service, or in the products provided as a part of, or otherwise in connection with, the TradeJorno Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by TradeJorno or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, TradeJorno does not warrant that the TradeJorno Service will be uninterrupted, uncorrupted, timely, or error-free.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Financial Disclaimer</Heading>
          <Text mb='10px'>The information contained on this Website and the resources available for download through this website is not intended as, and shall not be understood or construed as, financial advice. The Company is not an investment, financial, tax, or legal advisor or a broker-dealer and does not purport to provide personalized investment, financial, tax, or legal advice in any form. The Company does not recommend the purchase of particular securities/coins/tokens nor does the Company promise or guarantee any particular trading/investment results. You understand and acknowledge that there is a very high degree of risk involved in trading securities and, in particular, in trading derivatives or leveraged investment products, and in trading crypto. You acknowledge and agree that you, and not the Company, are solely responsible for your own investment research and decisions. You understand that the Company encourages you to seek the advice of a qualified securities professional and/or tax or legal advisor, as necessary, before making any investment, and to investigate and fully understand any and all risks before investing. None of the information provided is intended to be or should be interpreted as a promise or guarantee of any particular result. You should always check with your licensed financial, investment, legal, or tax advisor to determine the suitability of any investment. The Company assumes no responsibility or liability for your trading and investment results and you agree to hold the Company harmless for any such results or losses.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Definitions and key terms</Heading>
          <Text mb='10px'>To help explain things as clearly as possible in this Terms & Conditions, every time any of these terms are referenced, are strictly defined as:</Text>
          <UnorderedList>
            <ListItem>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</ListItem>
            <ListItem>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to TradeJorno Pty ltd, Australia that is responsible for your information under these Terms & Conditions.</ListItem>
            <ListItem>Country: where TradeJorno or the owners/founders of TradeJorno are based, in this case is Australia.</ListItem>
            <ListItem>Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit TradeJorno and use the services.</ListItem>
            <ListItem>Service: refers to the service provided by TradeJorno as described in the relative terms (if available) and on this platform.</ListItem>
            <ListItem>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</ListItem>
            <ListItem>Website: TradeJorno's site, which can be accessed via this URL: tradejorno.com</ListItem>
            <ListItem>You: a person or entity that is registered with TradeJorno to use the Services.</ListItem>
          </UnorderedList>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Restrictions</Heading>
          <Text mb='10px'>You agree not to, and you will not permit others to:</Text>
          <UnorderedList>
            <ListItem>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the website or make the platform available to any third party.</ListItem>
            <ListItem>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the website.</ListItem>
            <ListItem>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of TradeJorno or its affiliates, partners, suppliers or the licensors of the website.</ListItem>
          </UnorderedList>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Conduct on site</Heading>
          <Text mb='10px'>In accessing or using the Company Sites and Materials, you may not:</Text>
          <UnorderedList>
            <ListItem>Restrict or inhibit any other visitor from using the Company Sites and Materials, including, without limitation, by means of “hacking” or defacing any portion of the websites;</ListItem>
            <ListItem>Use the Company Sites and Materials for any unlawful purpose;</ListItem>
            <ListItem>Express or imply that any statements you make are endorsed by us, without our prior express written consent;</ListItem>
            <ListItem>Share, disseminate, or distribute any content, products, services, or materials available through your paid subscription or service;</ListItem>
            <ListItem>Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the Company Sites and Materials;</ListItem>
            <ListItem>“Frame” or “mirror” any part of the Company Sites and Materials without our prior written authorization;</ListItem>
            <ListItem>Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to download, retrieve, index, “data mine”, “scrape”, “harvest” or in any way reproduce or circumvent the navigational structure or presentation of the websites or its contents;</ListItem>
            <ListItem>Harvest or collect information about visitors to the Company Sites and Materials without their express consent;</ListItem>
            <ListItem>Send unsolicited or unauthorized advertisements, spam, chain letters, etc., to other users of the Company Sites and Materials;</ListItem>
            <ListItem>Infringe any party’s intellectual property rights;</ListItem>
            <ListItem>Threaten any of our users in any way;</ListItem>
            <ListItem>Incite hatred or promote materials that violate our users’ civil rights;</ListItem>
            <ListItem>Include or use profanity, vulgarity, obscenity or pornography;</ListItem>
            <ListItem>Publish any other user’s personally identifiable information (e.g. credit or debit card numbers, social security numbers, driver’s license);</ListItem>
            <ListItem>Impersonate any other user or person in a manner intended to mislead or confuse other users or engage in inappropriate, unethical or misleading behavior;</ListItem>
            <ListItem>Engage or promote illegal activities;</ListItem>
            <ListItem>Send spam or transmit any malware or viruses or other harmful computer code, files or programs; and.</ListItem>
            <ListItem>Solicit or advertise on the websites or add abusive or non-related linking to your own site;</ListItem>
          </UnorderedList>
          <Text mb='10px' mt='10px'>In order to access some of the services of the websites, you will need to create an account. By creating this account you agree to:</Text>
          <UnorderedList>
            <ListItem>Maintain only a single account;</ListItem>
            <ListItem>Provide accurate and complete information;</ListItem>
            <ListItem>Never share your account username, password or secrets or knowingly provide or authorize access to your account (including without limitation any subscription, products, services, or materials made available by the Company);</ListItem>
            <ListItem>Never use another user’s account without permission;</ListItem>
            <ListItem>Be solely responsible for the activity that occurs on your account, and keep your account password, secrets and recovery code secure; and</ListItem>
            <ListItem>Notify us immediately of any breach of security or unauthorized use of your account.</ListItem>
          </UnorderedList>
          <Text mb='10px' mt='10px'>You may not circumvent any measures we may put in place to protect the privacy or confidentiality of your account or information, or the account or information of ourselves, our affiliates, or other users, including encryption measures or the use of multi-factor authentication.</Text>
          <Text mb='10px' mt='10px'>You will at all times adhere to our <ChakraLink as={ReactRouterLink} to='/home/privacy' color={'blue.500'}>PRIVACY</ChakraLink> policy.</Text>
        </Flex>



        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Subscription and Payment</Heading>
          <Text mb='10px'>In connection with any purchase of services or materials from the Company, the Company does not make any promise regarding the continuation of any current features or functionality or delivery of any future functionality or features.</Text>
          <Text mb='10px'>If you purchase a subscription to any Company service or material, by authorizing the Company to charge a payment card for the fees associated with your subscription(s), you are authorizing the Company to automatically continue charging that card (or any replacement card issued by the card issuer) for all fees or charges associated with your subscription, including any renewal fees as described below. The Company may at any time change any of its pricing, or institute new charges or fees. Price changes and new charges announced during your subscription term for a service will apply to subsequent subscription terms. During the term of your subscription, you agree to inform the Company of any payment card information changes.</Text>
          <Text mb='10px'>Your subscriptions will be set to automatically renew upon expiration. This means that unless you cancel your subscription prior to its expiration, your account will automatically renew for the same term.</Text>
          <Text mb='10px'>If you cancel or terminate a subscription, you acknowledge and agree that any refunds will be in the Company’s sole discretion. Your obligations hereunder, including your obligation to pay amounts owed to us under these Terms for use of or access to our services or materials, including subscriptions, shall survive expiration or termination of these Terms and your cessation of use of the Company Sites and Materials.</Text>
          <Text mb='10px'>You are responsible for the payment of any taxes associated with the purchase of services or materials from the Company.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Refund Policy</Heading>
          <Text mb='10px'>Thanks for using TradeJorno. We appreciate the fact that you like to use the stuff we build. We also want to make sure you have a rewarding experience while you’re exploring, evaluating, and subscribing to our service.</Text>
          <Text mb='10px'>As with any subscription service, there are terms and conditions that apply to transactions at TradeJorno. By placing an order or making a purchase at TradeJorno, you agree to the terms along with TradeJorno's <ChakraLink as={ReactRouterLink} to='/home/privacy' color={'blue.500'}>PRIVACY</ChakraLink> policy.</Text>
          <Text mb='10px'>You are free to cancel your subscription at ANY time via the 'Subscription' page, and you can continue to use all the features of the service you have subscribed to until your subscription expires.</Text>
          <Text mb='10px'>We DO NOT provide refunds for any remaining subscription periods already paid for.</Text>
          <Text mb='10px'>If, for any reason, You are not completely satisfied with the service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Security</Heading>
          <Text mb='10px'>No measures designed to protect, secure, or preserve the integrity or confidentiality of information, including methods of transmission over the Internet or method of electronic storage, can guarantee the security of your information. The Company makes no assurances regarding the security of your information.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Your Suggestions</Heading>
          <Text mb='10px'>Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to TradeJorno with respect to the website shall remain the sole and exclusive property of TradeJorno.</Text>
          <Text mb='10px'>TradeJorno shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Your Consent</Heading>
          <Text mb='10px'>We've updated our Terms & Conditions to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website, registering an account, or making a purchase, you hereby consent to our Terms & Conditions.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Links to Other Websites</Heading>
          <Text mb='10px'>This Terms & Conditions applies only to the Services. The Services may contain links to other websites not operated or controlled by TradeJorno. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Terms & Conditions are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Cookies</Heading>
          <Text mb='10px'>TradeJorno uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Changes To Our Terms & Conditions</Heading>
          <Text mb='10px'>You acknowledge and agree that TradeJorno may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at  sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform TradeJorno when you stop using the Service. You acknowledge and agree that if TradeJorno disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account.</Text>
          <Text mb='10px'>If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the Terms & Conditions modification date above.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Modifications to Our website</Heading>
          <Text mb='10px'>TradeJorno reserves the right to modify, suspend or discontinue, temporarily or permanently, the website or any service to which it connects, with or without notice and without liability to you.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Updates to Our website</Heading>
          <Text mb='10px'>TradeJorno may from time to time provide enhancements or improvements to the features/ functionality of the website, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").</Text>
          <Text mb='10px'>Updates may modify or delete certain features and/or functionalities of the website. You agree that TradeJorno has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the website to you.</Text>
          <Text mb='10px'>You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and (ii) subject to the terms and conditions of this Agreement.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Third-Party Services</Heading>
          <Text mb='10px'>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</Text>
          <Text mb='10px'>You acknowledge and agree that TradeJorno shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. TradeJorno does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</Text>
          <Text mb='10px'>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Term and Termination</Heading>
          <Text mb='10px'>This Agreement shall remain in effect until terminated by you or TradeJorno.</Text>
          <Text mb='10px'>TradeJorno may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</Text>
          <Text mb='10px'>This Agreement will terminate immediately, without prior notice from TradeJorno, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the website and all copies thereof from your computer.</Text>
          <Text mb='10px'>Upon termination of this Agreement, you shall cease all use of the website and delete all copies of the website from your computer.</Text>
          <Text mb='10px'>Termination of this Agreement will not limit any of TradeJorno's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Copyright Infringement Notice</Heading>
          <Text mb='10px'>If you are a copyright owner or such owner’s agent and believe any material on our website constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including your address, telephone number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners; and (e) the a statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Indemnification</Heading>
          <Text mb='10px'>You agree to indemnify and hold TradeJorno and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the website; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>No Warranties</Heading>
          <Text mb='10px'>The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, TradeJorno, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, TradeJorno provides no warranty or undertaking, and makes no representation of any kind that the website will meet your requirements, achieve any intended results, be compatible or work with any other software, , systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</Text>
          <Text mb='10px'>Without limiting the foregoing, neither TradeJorno nor any TradeJorno's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the website, or the information, content, and materials or products included thereon; (ii) that the website will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the website; or (iv) that the website, its servers, the content, or e-mails sent from or on behalf of TradeJorno are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</Text>
          <Text mb='10px'>Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Limitation of Liability</Heading>
          <Text mb='10px'>Notwithstanding any damages that you might incur, the entire liability of TradeJorno and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for services on the website.</Text>
          <Text mb='10px'>To the maximum extent permitted by applicable law, in no event shall TradeJorno or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the website, third-party software and/or third-party hardware used with the website, or otherwise in connection with any provision of this Agreement), even if TradeJorno or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</Text>
          <Text mb='10px'>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Severability</Heading>
          <Text mb='10px'>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</Text>
          <Text mb='10px'>This Agreement, together with the Privacy Policy and any other legal notices published by TradeJorno on the Services, shall constitute the entire agreement between you and TradeJorno concerning the Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and TradeJorno's failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND TradeJorno AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Waiver</Heading>
          <Text mb='10px'>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.</Text>
          <Text mb='10px'>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Amendments to this Agreement</Heading>
          <Text mb='10px'>TradeJorno reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</Text>
          <Text mb='10px'>By continuing to access or use our website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use TradeJorno.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Entire Agreement</Heading>
          <Text mb='10px'>The Agreement constitutes the entire agreement between you and TradeJorno regarding your use of the website and supersedes all prior and contemporaneous written or oral agreements between you and TradeJorno.</Text>
          <Text mb='10px'>You may be subject to additional terms and conditions that apply when you use or purchase other TradeJorno's  services, which TradeJorno will provide to you at the time of such use or purchase.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Updates to Our Terms</Heading>
          <Text mb='10px'>We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Intellectual Property</Heading>
          <Text mb='10px'>The website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by TradeJorno, its licensors or other providers of such material and are protected by  and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of TradeJorno, unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the material is prohibited.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Agreement to Arbitrate</Heading>
          <Text mb='10px'>This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR TradeJorno's INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other controversy between you and TradeJorno concerning the Services or this agreement, whether in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible meaning allowable under law.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Notice of Dispute</Heading>
          <Text mb='10px'>In the event of a dispute, you or TradeJorno must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to: <a href="mailto:support@tradejorno.com">support@tradejorno.com</a>. TradeJorno will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and TradeJorno will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or TradeJorno may commence arbitration.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Binding Arbitration</Heading>
          <Text mb='10px'>If you and TradeJorno don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration as described in this section. You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Submissions and Privacy</Heading>
          <Text mb='10px'>In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data or proposals, including ideas for new or improved products, services, features, technologies or promotions, you expressly agree that such submissions will automatically be treated as non-confidential and non-proprietary and will become the sole property of TradeJorno without any compensation or credit to you whatsoever. TradeJorno and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Promotions</Heading>
          <Text mb='10px'>TradeJorno may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”) that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules.</Text>
          <Text mb='10px'>Additional terms and conditions may apply to purchases of goods or services on or through the Services, which terms and conditions are made a part of this Agreement by this reference.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Typographical Errors</Heading>
          <Text mb='10px'>In the event a product and/or service is listed at an incorrect price or with incorrect information due to typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or other payment account in the amount of the charge.</Text>
        </Flex>

        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Miscellaneous</Heading>
          <Text mb='10px'>If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an authorized representative of TradeJorno. TradeJorno will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. TradeJorno operates and controls the TradeJorno Service from its offices in Australia. The Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those persons who choose to access the TradeJorno Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms & Conditions (which include and incorporate the TradeJorno Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between you and TradeJorno concerning its subject matter, and cannot be changed or modified by you. The section headings used in this Agreement are for convenience only and will not be given any legal import.</Text>
        </Flex>


        <Flex direction={'column'} w='100%' mt='20px'>
          <Heading mb='20px' size="md" fontWeight="bold" color={textColor}>Contact Us</Heading>
          <Text mb='80px'>Don't hesitate to <ChakraLink as={ReactRouterLink} to='mailto:support@tradejorno.zohodesk.com.au' color={'blue.500'}>CONTACT</ChakraLink>  us if you have any questions.</Text>
        </Flex>

      </Flex>

    </LandingOtherLayout>
  )
}

export default Terms