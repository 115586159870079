import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormLabel, Icon, Select, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { MdFullscreen } from 'react-icons/md'
import DrawdownChart from '../charts/DrawdownChart'
import EquityChart from '../charts/EquityChart'
import ProfitFactorWinChart from '../charts/ProfitFactorWinChart'
import PeriodCharts from '../stats/PeriodCharts'
import TimeCharts from '../stats/TimeCharts'
import { Account, drawdownChartData, equityChartData, profitWinChartData, Trade } from 'views/trader/trades/variables/types'
import { equityChartValues, profitFactorWinValues, drawdownValues } from '../../variables/DashChartData'
import { useData } from 'services/data/DataProvider'

const ChartModal = (props: {}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { periodtrades, defaultaccount, defaultgroup, groupAccounts, defaultcurrency } = useData();
    const btnRef = React.useRef()
    const drawerBg = useColorModeValue('white', 'navy.800');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const cardRef = useRef(null);
    const [chartType, setChartType] = useState("");
    const [equityData, setEquityData] = useState<equityChartData>({ color: "", initBalance: 0, equityChange: 0, series: [{ name: "", data: [], color: "", type: "" }], categories: [] });
    const [profitWinData, setProfitWinData] = useState<profitWinChartData>({ labels: [], categories: [], series: [{ name: '', data: [], color: '', type: '' }] });
    const [drawdownData, setDrawdownData] = useState<drawdownChartData>({ axis: [], series: [{ name: '', data: [], color: '', type: '' }] });

    const changeChart = (e: ChangeEvent<HTMLSelectElement>) => {
        setChartType(e.target.value);
        localStorage.setItem("chart", e.target.value);
    }

    useEffect(() => {
        periodtrades.sort((a: Trade, b: Trade) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()); // sort first to last here
        updateVariables();
    }, [periodtrades])

    const updateVariables = async () => {
        if (defaultaccount) {
            calculateChartData(periodtrades, [defaultaccount]);
        }
        else {
            calculateChartData(periodtrades, groupAccounts);
        }
    }

    const calculateChartData = (trades: Trade[], accounts: Account[]) => {
        equityChartValues(trades, accounts, defaultaccount, defaultgroup, defaultcurrency).then((d) => {
            setEquityData(d);
        });
        profitFactorWinValues(trades, defaultcurrency).then((d) => {
            setProfitWinData(d);
        })
        drawdownValues(trades, accounts, defaultcurrency).then((d) => {
            setDrawdownData(d);
        })
    }

    const openmodal = () =>{
        setChartType(localStorage.getItem("chart") ? localStorage.getItem("chart") : "time");
        onOpen();
    }

    return (
        <>
            <Button variant='outline' onClick={openmodal} borderRadius={'5px'} size={'sm'} ms='5px'>
                <Icon as={MdFullscreen} boxSize={5} />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent bg={drawerBg} h='100%'>
                    <DrawerCloseButton />
                    <DrawerHeader>Charts</DrawerHeader>
                    <DrawerBody>
                        <Card borderColor={bdColor} borderWidth='1px' h='100%' minH={'500px'} bg='transparent'>
                            <Flex direction={'row'} justify={'space-between'} mb='20px'>
                                <FormLabel fontWeight='bold' fontSize='md' mb='8px'>Charts</FormLabel>
                                <Flex direction={'row'}>
                                    <Select size={'sm'} w='150px' borderRadius={'5px'} defaultValue={chartType} onChange={changeChart}>
                                        <option value='period'>Period P/L</option>
                                        <option value='time'>Time Analytics</option>
                                        <option value='equity'>Equity Curve</option>
                                        <option value='pfwin'>Profit factor Win%</option>
                                        <option value='ddown'>Drawdown</option>
                                    </Select>

                                </Flex>
                            </Flex>

                            {chartType === "equity" && <Card h='100%' w='100%' p='0px' bg='transparent'>
                                <EquityChart equityChartData={equityData} hidden={chartType !== "equity"} showtoolbar={true} height={'95%'} />
                            </Card>}
                            {chartType === "pfwin" && <Card h='100%' p='0px' bg='transparent'>
                                <ProfitFactorWinChart profitWinData={profitWinData} hidden={chartType !== "pfwin"} showtoolbar={true} height={'95%'} />
                            </Card>}
                            {chartType === "ddown" && <Card h='100%' p='0px' bg='transparent'>
                                <DrawdownChart drawdownData={drawdownData} hidden={chartType !== "ddown"} showtoolbar={true} height={'95%'} />
                            </Card>}
                            {chartType === "time" && <TimeCharts hidden={chartType !== "time"} />}
                            {chartType === "period" && <PeriodCharts hidden={chartType !== "period"} minHeight='550px' />}
                        </Card>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ChartModal