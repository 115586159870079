import { Progress, ProgressLabel } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import React from 'react'
import { scoreColour } from '../../variables/FormatData';

const RuleScore = (props: { rulescore: number, width: string, size: string }) => {

    const progresstextColor = useColorModeValue('gray.300', 'gray.500');
    const progressBgColor = useColorModeValue('gray.100', 'gray.600');

    return (
        <Progress bg={progressBgColor} cursor="pointer" w={props.width} colorScheme={scoreColour(props.rulescore)} size={props.size} value={props.rulescore}>
            <ProgressLabel color={progresstextColor} fontSize={'11px'}>{props.rulescore.toFixed()}%</ProgressLabel>
        </Progress>
    )
}

export default RuleScore