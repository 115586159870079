import { Box, Center, Divider, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card'
import { useEffect, useState } from 'react';
import { useData } from 'services/data/DataProvider';
import { currencyformat, getDuration } from 'views/trader/trades/variables/FormatData';
import { StatLose, StatWin } from 'views/trader/trades/variables/types';

const WinLossStats = (props: { loading: boolean, winners: StatWin, losers: StatLose, currency: string }) => {

  const { winners, losers, currency, loading } = props;
  const { periodtrades } = useData();

  const bdColor = useColorModeValue('gray.200', 'gray.700');

  const spinnerColor = useColorModeValue('gray.300', 'brand.300');
  const bgcolor = useColorModeValue('white', 'navy.900');
  const barcolor = useColorModeValue('gray.300', 'gray.600');
  const divcolor = useColorModeValue('gray.500', 'white');

  const [wincol, setWincol] = useState('green.500');
  const [losscol, setLosscol] = useState('red.500');

  useEffect(() => {
    setWincol(periodtrades.length ? 'green.500' : barcolor)
    setLosscol(periodtrades.length ? 'red.500' : barcolor)
  }, [periodtrades, barcolor])

  const getwidth = (winval: number, lossval: number) => {
    let total = winval + Math.abs(lossval);
    let winp = total > 0 ? (winval / total) * 100 : 0;
    let losp = total > 0 ? (Math.abs(lossval) / total) * 100 : 0;
    let winw = total > 0 ? winp.toFixed() + '%' : '50%'
    let losw = total > 0 ? losp.toFixed() + '%' : '50%'
    return [winw, losw];
  }


  return (
    <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' mt='10px'>

      <Flex>
        <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Winners vs Losers</Heading>
      </Flex>

      <Box h={'100%'} justifyContent={'center'} alignContent={'center'} hidden={!loading}>
        <Flex justify={'center'}>
          <Spinner color={spinnerColor}></Spinner>
        </Flex>
      </Box>

      <Center>

        <Flex w={'100%'} h='90%' position={'absolute'} justify={'center'} hidden={loading} mt='20px'>
          <Divider orientation='vertical' borderWidth={'2px'} borderColor={divcolor} variant='dashed' />
        </Flex>

        <Flex w={'100%'} direction={'column'} hidden={loading}>

          <Flex direction={'column'}>

            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Winners</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Losers</Text>
              </Flex>
            </Flex>

            <Flex direction={'row'} w='100%' gap={1}>

              <Box w={getwidth(winners.wintotal, losers.losstotal)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                {(winners.wintotal > 0 || winners.wintotal + losers.losstotal === 0) &&
                  <Flex justify={'start'}>
                    <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{winners.wintotal}{'  ('}{winners.winperc.toFixed()}%{')'}</Text>
                  </Flex>}
              </Box>

              <Box w={getwidth(winners.wintotal, losers.losstotal)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                {(losers.losstotal > 0 || winners.wintotal + losers.losstotal === 0) &&
                  <Flex justify={'end'}>
                    <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{losers.losstotal}{'  ('}{losers.lossperc.toFixed()}%{')'}</Text>
                  </Flex>}
              </Box>
            </Flex>
          </Flex>

          <Flex direction={'column'} mt='10px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>AVG Win</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>AVG Loss</Text>
              </Flex>
            </Flex>
            <Flex direction={'row'} w='100%' gap={1}>
              <Box w={getwidth(winners.avgwin, losers.avgloss)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'start'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{currencyformat(winners.avgwin, currency)}</Text>
                </Flex>
              </Box>
              <Box w={getwidth(winners.avgwin, losers.avgloss)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'end'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{currencyformat(losers.avgloss, currency)}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex direction={'column'} mt='10px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Biggest Win</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Biggest Loss</Text>
              </Flex>
            </Flex>
            <Flex direction={'row'} w='100%' gap={1}>
              <Box w={getwidth(winners.maxwin, losers.maxloss)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'start'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{currencyformat(winners.maxwin, currency)}</Text>
                </Flex>
              </Box>
              <Box w={getwidth(winners.maxwin, losers.maxloss)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'end'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{currencyformat(losers.maxloss, currency)}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex direction={'column'} mt='10px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Best day</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Worst day</Text>
              </Flex>
            </Flex>
            <Flex direction={'row'} w='100%' gap={1}>
              <Box w={getwidth(winners.bestday, losers.worstday)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'start'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{currencyformat(winners.bestday, currency)}</Text>
                </Flex>
              </Box>
              <Box w={getwidth(winners.bestday, losers.worstday)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'end'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{currencyformat(losers.worstday, currency)}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex direction={'column'} mt='10px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Winning days</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Losing days</Text>
              </Flex>
            </Flex>
            <Flex direction={'row'} w='100%' gap={1}>
              <Box w={getwidth(winners.windays, losers.lossdays)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'start'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{winners.windays}</Text>
                </Flex>
              </Box>
              <Box w={getwidth(winners.windays, losers.lossdays)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'end'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{losers.lossdays}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex direction={'column'} mt='10px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Max win streak</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Max loss streak</Text>
              </Flex>
            </Flex>
            <Flex direction={'row'} w='100%' gap={1}>
              <Box w={getwidth(winners.winstreak, losers.losstreak)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'start'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{winners.winstreak}</Text>
                </Flex>
              </Box>
              <Box w={getwidth(winners.winstreak, losers.losstreak)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'end'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{losers.losstreak}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex direction={'column'} mt='10px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Flex direction={'column'}>
                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>AVG win duration</Text>
              </Flex>
              <Flex direction={'column'}>
                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>AVG loss duration</Text>
              </Flex>
            </Flex>
            <Flex direction={'row'} w='100%' gap={1}>
              <Box w={getwidth(winners.avg_duration, losers.avg_duration)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'start'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{getDuration(winners.avg_duration)}</Text>
                </Flex>
              </Box>
              <Box w={getwidth(winners.avg_duration, losers.avg_duration)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                <Flex justify={'end'}>
                  <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{getDuration(losers.avg_duration)}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>


        </Flex>
      </Center>







      {/*<Flex direction={'column'} hidden={loading}>
        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Total</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{winners.wintotal}{'  ('}{winners.winperc.toFixed()}%{')'}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdNumbers} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px' >AVG win</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(winners.avgwin, currency)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={PiMathOperationsBold} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Biggest win</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(winners.maxwin, currency)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdAdd} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Best day</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(winners.bestday, currency)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={LuCalendarCheck2} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Winning days</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{winners.windays}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={LuCalendarPlus} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Max win streak</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{winners.winstreak}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdOutlineTrendingUp} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600">AVG win duration</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{getDuration(winners.avg_duration)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdOutlineTimer} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>
      </Flex>*/}
    </Card>
  )
}

export default WinLossStats