import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { useRef } from 'react'
import { RiDeleteBin2Line } from 'react-icons/ri'

const TradeLogicDelete = (props: {deletefunction: any, category: string, val: string, hidden: boolean, loading: boolean }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const confirmDelete = () => {
        props.deletefunction(props.category, props.val)
        onClose();
    }

    return (
        <>
            <Button borderRadius={'5px'} size={'sm'} ms='5px' variant={'ghost'} p={1} hidden={props.hidden} onClick={onOpen} isLoading={props.loading}>
                <Icon as={RiDeleteBin2Line} boxSize={6} color={'red.500'}></Icon>
            </Button>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete {props.val.toUpperCase()}?
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                The variable will remain on trades where already used until it's deleted manually.
                            </Text>

                            <Text fontWeight={'700'} mt='20px' color={'red.500'}>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} variant={'ghost'} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' variant={'outline'} onClick={confirmDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default TradeLogicDelete