
import Chart from 'react-apexcharts';
import { Box, Flex, Heading, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useEffect, useState } from 'react';
import { StatSide } from 'views/trader/trades/variables/types'
import { useData } from 'services/data/DataProvider';

const SideStatDonut = (props: { stats: StatSide, showtrades: any }) => {

    const { stats, showtrades } = props;
    const { periodtrades } = useData();
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
    const bgcolor = useColorModeValue('white', 'navy.900');

    useEffect(() => {
        let newd = [stats.long, stats.short]
        setState({
            ...state,
            series: newd
        })
    }, [stats])

    const [state, setState] = useState({
        series: [],
        options: {
            labels: ["Long", "Short"],
            colors: ['#01B574', '#EE5D50'],
            chart: {
                toolbar: {
                    show: false
                },
                events: {
                    click: function (event: any, chartContext: any, config: { dataPointIndex: number; }) {
                        if (config.dataPointIndex > -1) {
                            let side = (config.dataPointIndex === 0 ? 'buy' : 'sell')
                            showtrades(side);
                        }
                    }
                }
            },
            stroke: {
                width: 0
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    customScale: 1,
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    donut: {
                        size: '60%',
                        background: 'transparent',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '14px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -5,

                            },
                            value: {
                                show: true,
                                fontSize: '12px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                                color: '#848287',
                                offsetY: 0,

                            },
                            total: {
                                show: true,
                                showAlways: false,
                                label: 'Total',
                                fontSize: '14px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                                color: '#848287',
                            }
                        }
                    },
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    type: 'vertical',
                    shadeIntensity: 0.15,
                    opacityFrom: 0.9,
                    opacityTo: 1
                }
            },
            legend: {
                show: false
            },
            dataLabels: {

                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    fontFamily: 'DM Sans',
                    fontWeight: 'bold',
                    colors: undefined
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#848287',
                    opacity: 0.5,
                    dropShadow: {
                        enabled: false,
                    }
                },
                dropShadow: {
                    enabled: false,
                }
            },
            grid: {
                show: false,
            }
        },
    });

    return (
        <>
            <Card h='100%' borderWidth='1px' bg='transparent' mt='10px'>
                <Flex>
                    <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Long/Short</Heading>
                </Flex>

                {periodtrades.length < 1 ? (
                    <Flex justify={'center'} align={'center'} >
                        <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                            <Box p='10px' color={textColorSecondary} >
                                <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                            </Box>
                        </ScaleFade>
                    </Flex>
                ) : (
                    <Flex align={'center'} justify={'center'} w='100%' h='100%' p='10px'>
                        <Chart
                            type='donut'
                            options={state.options}
                            series={state.series}
                        />
                    </Flex>
                )}
            </Card>
        </>
    )
}

export default SideStatDonut