/* eslint-disable */
import {
	Badge, Flex, Table, Tbody, Td, Text,
	Th, Thead, Tr, useColorModeValue, Divider, IconButton,
	NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
	Select,
	Button,
	Icon,
	useToast,
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	SortingState,
	PaginationState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table';
// Custom components  
//import TradeModal from '../modals/TradeModal';

import { Account, AccountGroup, Trade, TradeStrategy } from 'views/trader/trades/variables/types';
import { useEffect, useRef, useState } from 'react';
import { getDuration, currencyformat, numberColor } from 'views/trader/trades/variables/FormatData';
import { MdAdd, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { db } from 'services/db';

import TradeDrawer from 'views/trader/trades/components/modals/TradeDrawerLayout2';
import RuleScore from 'views/trader/trades/components/fields/RuleScore';
import { getDefaultGroup, updateStratsAndMistakes } from 'services/dbservice';
import dayjs from 'dayjs';
import { updateTrade } from 'services/apiservice';
import useNewAccount from '../trades/hooks/useNewAccount';
import useNewAccountGroup from '../trades/hooks/useNewAccountGroup';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';

const columnHelper = createColumnHelper<Trade>();

export default function ShowTradesTable(props: { trades: Trade[], closemodal: any }) {

	const { user, muuid } = useAuth();//useContext(AuthContext);
	const { dateformat } = useData();

	const [data, setData] = useState<Trade[]>([]);
	const [loading, setLoading] = useState(false);
	const [sorting, setSorting] = useState<SortingState>([]);
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [tablesize, setTablesize] = useState(localStorage.getItem('tablesize') ? Number(localStorage.getItem('tablesize')) : 10);
	const [defaultGroup, setDefaultGroup] = useState<AccountGroup>(useNewAccountGroup());
	//const [dateformat, setDateFormat] = useState(localStorage.getItem("dateformat") ? localStorage.getItem("dateformat") : "YYYY-MM-DD HH:mm")
	const tradeDrawerRef = useRef(null);

	const [updatedStrategies, setUpdatedStrategies] = useState<any[]>([]);
	const [updatedMistakes, setUpdatedMistakes] = useState<any[]>([]);
	const [accounts, setAccounts] = useState<Account[]>([]);

	const newaccount = useNewAccount();
	const toast = useToast();

	useEffect(() => {
		getDefaultGroup(user.id).then(async (grp) => {
			setDefaultGroup(grp);
		})
		db.accounts.where({ userid: user.id }).toArray().then((accs) => {
			setAccounts(accs);
			
			setData(props.trades);
		})

	}, [props.trades])


	const updateSandMonly = async () => {
		await updateStratsAndMistakes(updatedStrategies, updatedMistakes).then((r) => {
			setLoading(false);
		});
	}
	const addStratToUpdate = (s: TradeStrategy) => {
		setUpdatedStrategies([...updatedStrategies, s]);
	}

	const addmisttoupdate = (m: { name: string; id: number }) => {
		setUpdatedMistakes([...updatedMistakes, m]);
	}

	const getAccount = (accountid: number) => {
		const arr = accounts.filter((a: any) => a.accountid === accountid);
		if (arr.length) {
			return arr[0];
		}
		else {
			return newaccount;
		}
	}

	const showTrade = async (tradeid: number) => {
		await db.trades.get(tradeid).then(async (tr) => {
			if (tr !== undefined) {
				tradeDrawerRef.current.openModal(tr);
			}
			else {
				toast({ title: 'Oops, trade not found ...', description: "Please open the account drawer and click 'Re-Sync Data'", status: 'info', position: 'top', duration: 2000, isClosable: false });
			}
		}).catch((e) => {
			toast({ title: 'Oops, error fetching trade ...', description: "Please refresh your browser window", status: 'info', position: 'top', duration: 2000, isClosable: false });
		})
	}

	const updatetrade = (trade: Trade) => {
		let index = data.findIndex((t) => t.tradeid === trade.tradeid);
		if (index > -1) {
			setData([...data.slice(0, index), trade, ...data.slice(index + 1)]);
		}
	}
	const removetrade = (tradeid: number) => {
		let tr = data.filter((t) => t.tradeid !== tradeid);
		if (!tr.length) {
			props.closemodal();
		}
		else {
			setData(tr);
		}
	}

	const savetrade = async (trade: Trade) => {

		setLoading(true);

		delete trade.sync_due; //only used locally

		let str = trade.notes.replace(/(<([^>]+)>)/ig, '').trim();
		if (!str.length) {
			trade.notes = "";
		}
		trade.notes = trade.notes.trim();
		trade.last_uuid = muuid;

		await updateTrade(trade).then(async (r) => {
			trade.sync_due = r;
			await db.trades.put(trade, trade.tradeid).then(async () => {
				updatetrade(trade);
			}).catch(error => { });
		});

		updateSandMonly();

	}

	const columns = [
		columnHelper.accessor('tradeid', {
			id: 'tradeid',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
				</Text>
			),
			cell: (info) => (
				<>
					{info.row.original.reviewed ?
						(
							<Button variant='outline' colorScheme={'green'} size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original.tradeid)} isDisabled={loading} isLoading={loading}>
								Reviewed +
							</Button>
						) : (
							<Button variant='outline' size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original.tradeid)} isDisabled={loading} isLoading={loading}>
								<Icon color='secondaryGray.500' as={MdAdd} w='20px' h='20px' />
							</Button>
						)}
					{/*<>
					<Button variant='outline' size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original.tradeid)} isLoading={loading}>
						<Icon color='secondaryGray.500' as={MdAdd} w='20px' h='20px' />
					</Button>
				</>*/}
				</>

			)
		}),
		columnHelper.accessor('datetime', {
			id: 'date',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DATE
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Flex direction='column'>
						<Text color={textColor} fontSize='sm' fontWeight='700'>
							{dayjs(info.getValue()).format(dateformat)}
						</Text>
						<Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
							{"Duration: " + getDuration(info.row.original.duration)}
						</Text>
					</Flex>
				</Flex>
			)
		}),
		columnHelper.accessor('accountid', {
			id: 'accountid',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ACCOUNT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{getAccount(info.getValue()).accname}
				</Text>

			)
		}),
		columnHelper.accessor('ticker', {
			id: 'ticker',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TICKER
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('side', {
			id: 'side',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIDE
				</Text>
			),
			cell: (info) => (

				<Badge
					colorScheme={info.getValue().toLocaleLowerCase() === "sell" ? 'red' : 'green'}
					color={info.getValue().toLocaleLowerCase() === "sell" ? 'red.500' : 'green.500'}
					fontSize='sm'
					fontWeight='600'>
					{info.getValue().toUpperCase()}
				</Badge>
			)
		}),
		columnHelper.accessor('size', {
			id: 'size',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIZE
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('entry_avg', {
			id: 'entry',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ENTRY
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600' >
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('exit_avg', {
			id: 'exit',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					EXIT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('points', {
			id: 'points',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					POINTS
				</Text>
			),
			cell: (info) => (
				<Text color={numberColor(info.getValue())} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('profitloss', {
			id: 'profitloss',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					P/L
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('fees', {
			id: 'fees',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					FEES
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('nett', {
			id: 'nett',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NETT
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		,
		columnHelper.accessor('rvalue', {
			id: 'rvalue',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					R-VALUE
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={(getValue() !== 0 ? (getValue() > 0 ? (getValue() < 1 ? 'orange.500' : 'green.500') : 'red.500') : textColorSecondary)} fontSize='sm' fontWeight='600'>
					{getValue() !== 0 ? getValue().toFixed(2) : 'No stop'}
				</Text>
			)
		}),
		columnHelper.accessor('rulescore', {
			id: 'rulescore',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.500'>
					RULE Score
				</Text>
			),
			cell: (info) => (
				<RuleScore rulescore={info.getValue()} width={'80%'} size={'lg'} />
			)
		})
	];

	const updatePageSize = (size: number) => {
		setTablesize(size);
		localStorage.setItem('tablesize', size.toFixed());
		table.setPageSize(size);
	}

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: tablesize,
	})
	const [columnVisibility, setColumnVisibility] = useState({});

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			pagination,
			columnVisibility
		},
		onColumnVisibilityChange: setColumnVisibility,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,

		debugTable: true
	});

	return (
		<>
			<Table variant='simple' color='gray.500' mt="12px">
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {

								return (
									<Th
										key={header.id}
										colSpan={header.colSpan}
										pe='10px'
										borderColor={borderColor}
										cursor='pointer'
										onClick={header.column.getToggleSortingHandler()}>
										<Flex
											justifyContent='space-between'
											align='center'
											fontSize={{ sm: '10px', lg: '12px' }}
											color='gray.400'>
											{flexRender(header.column.columnDef.header, header.getContext())}{{
												asc: '',
												desc: '',
											}[header.column.getIsSorted() as string] ?? null}
										</Flex>
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => {
						return (
							<Tr key={row.id}>
								{row.getVisibleCells().map((cell) => {
									return (
										<Td
											key={cell.id}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Divider mt='10px' hidden={table.getRowCount() <= 10} />
			<Flex direction={{ base: 'column', md: 'row' }} justify='center' hidden={table.getRowCount() <= 10}>
				<Flex direction='row' justify='center' hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
					<Text mt='23px' ms='20px' me='5px'>Page</Text>
					<Text as='b' mt='23px' ms='5px' me='20px'>{table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount().toLocaleString()}</Text>
					<Text mt='23px' color={textColorSecondary}>Go to page: </Text>
					<NumberInput ms='10px' mt='20px' size='sm' min={1} max={table.getPageCount()} w='85px' allowMouseWheel={true} hidden={table.getRowCount() <= table.getState().pagination.pageSize}
						onChange={(valueString) => table.setPageIndex(valueString ? Number(valueString) - 1 : 0)}
					>
						<NumberInputField color={textColor} borderRadius='5px' />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				</Flex>
				<Flex direction='row' justify='center'>
					<Select size='sm' mt='20px' ms='10px' me='10px' borderRadius='5px' alignContent='end' w='120px' value={tablesize}//table.getState().pagination.pageSize}
						onChange={e => { updatePageSize(Number(e.target.value)) }}
					>
						{[10, 20, 30, 40, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</Select>
					<Flex hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowLeft />} onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowLeft />} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowRight />} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowRight />} onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
					</Flex>
				</Flex>
			</Flex>
			<TradeDrawer ref={tradeDrawerRef} addUpdatedStrategy={addStratToUpdate} addUpdatedMistake={addmisttoupdate}  savetrade={savetrade} bulkupdate={false} updateSandMonly={updateSandMonly} removefromtable={removetrade}></TradeDrawer>
		</>
	);
}
