import dayjs from 'dayjs';
import { db } from 'services/db';
import { AccountExecutions, newExecution } from 'views/trader/trades/variables/types';
import Papa from 'papaparse';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export function getWealthchartsTrades(f: File): Promise<AccountExecutions[][]> {

    dayjs.extend(utc)
    dayjs.extend(timezone);

    return new Promise(async (resolve, reject) => {

        try {

            const accountarr: AccountExecutions[][] = [[], []];

            Papa.parse(f, {
                header: true,
                download: true,
                skipEmptyLines: true,
                delimiter: ",",
                complete: async (result: any) => {

                    try {
                        for (let e of result.data) {

                            if (Number(e.price_done.toString().trim()) !== 0) {

                                const ex = newExecution();

                                let symb = e.symbol.trim().split(".");
                                //let tick = e.chartSymbol.trim();
                                //tick = tick.substring(0, tick.length - 2)
                                ex.ticker = symb[1].trim().substring(0, symb[1].length - 2)
                                             
                                let inst = await db.instruments.where("ticker").equalsIgnoreCase(ex.ticker).toArray();

                                if (inst.length) {
                                   
                                    let d = dayjs.unix(e.order_date)
                                    let djs = d.tz(inst[0].timezone);
                                    ex.datetime = djs.format('YYYY-MM-DD HH:mm:ss');
                                    ex.instrument = inst[0].category;
                                    ex.currency = inst[0].currency;
                                    let qty = Number(e.qty_done);
                                    ex.size = Math.abs(qty);
                                    ex.side = (qty > 0 ? 'buy' : 'sell');
                                    ex.price = Number(e.price_done);
                                    ex.fee = 0;
                                    ex.contract = symb[1].trim();
                                    ex.orderid = e.order_id.trim();

                                    const acc = accountarr[0].find((a) => (a.account === e.account_id.trim()));
                                    if (acc) {
                                        acc.executions.push(ex);
                                    }
                                    else {
                                        let a: AccountExecutions = { account: e.account_id.trim(), executions: [] };
                                        a.executions.push(ex);
                                        accountarr[0].push(a);
                                    }
                                }
                                else {
                                    let d = dayjs.unix(e.order_date)
                                    let djs = d.tz("America/New_York");
                                    ex.datetime = djs.format('YYYY-MM-DD HH:mm:ss');
                                    ex.instrument = '';
                                    ex.currency = 'USD'
                                    let qty = Number(e.qty_done);
                                    ex.size = Math.abs(qty);
                                    ex.side = (qty > 0 ? 'buy' : 'sell');
                                    ex.price = Number(e.price_done);
                                    ex.fee = 0;
                                    ex.contract = symb[1].trim();
                                    ex.orderid = e.order_id.trim();

                                    const acc = accountarr[1].find((a) => (a.account === e.account_id.trim()));
                                    if (acc) {
                                        acc.executions.push(ex);
                                    }
                                    else {
                                        let a: AccountExecutions = { account: e.account_id.trim(), executions: [] };
                                        a.executions.push(ex);
                                        accountarr[1].push(a);
                                    }
                                }
                            }
                        }
                        //alert(JSON.stringify(accountarr[0]))
                        
                        resolve(accountarr);
                    }
                    catch (err) {
                        //alert(JSON.stringify("WC Error"))
                        reject(err);
                    }
                },
            })


        }
        catch (err) {
            //alert(JSON.stringify("WC Error"))
            reject(err);
        }
    });
}


