/* eslint-disable */

import {
	Badge, Flex, Table, Tbody, Td, Text,
	Th, Thead, Tr, useColorModeValue, ScaleFade, Box, Divider, IconButton,
	NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
	Button,
	Icon,
	useToast,
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	SortingState,
	PaginationState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table';
// Custom components  
//import TradeModal from '../modals/TradeModal';
import { Account, Trade, TradeStrategy } from '../../variables/types';
import { useEffect, useRef, useState } from 'react';
import { getDuration, currencyformat, numberColor } from '../../variables/FormatData';
import { MdAdd, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { db } from 'services/db';
import TradeDrawer from '../modals/TradeDrawerLayout2';
import { updateStratsAndMistakes } from 'services/dbservice';
import dayjs from 'dayjs';
import { updateTrade } from 'services/apiservice';
import useNewAccount from '../../hooks/useNewAccount';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';


const columnHelper = createColumnHelper<Trade>();

export default function TradesTableDash() {

	const { user, muuid } = useAuth();//useContext(AuthContext);


	const [data, setData] = useState<Trade[]>([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [tablesize, setTablesize] = useState(8);
	//const [defaultGroup, setDefaultGroup] = useState<AccountGroup>(useNewAccountGroup());
	const { defaultgroup, tradesflagged, dateformat } = useData();
	const tradeDrawerRef = useRef(null);

	const [accounts, setAccounts] = useState<Account[]>([]);
	const [updatedStrategies, setUpdatedStrategies] = useState<any[]>([]);
	const [updatedMistakes, setUpdatedMistakes] = useState<any[]>([]);

	const newaccount = useNewAccount();
	const toast = useToast();

	useEffect(() => {
		setLoading(true);

		db.accounts.where({ userid: user.id }).toArray().then((accs) => {
			setAccounts(accs);
		})

		//setDefaultGroup(props.defaultGroup);
		//props.trades.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
		setData(tradesflagged);
		setLoading(false);
		setTimeout(() => {
			setLoading2(false);
		}, 1000);

	}, [tradesflagged])

	const updateSandMonly = async () => {
		await updateStratsAndMistakes(updatedStrategies, updatedMistakes).then((r) => {
			setLoading(false);
		});
	}

	const getAccountName = (accountid: number) => {
		const arr = accounts.filter((a: any) => a.accountid === accountid);
		if (arr.length) {
			return arr[0].accname;
		}
		else {
			return "";
		}
	}

	const showTrade = async (tradeid: number) => {
		await db.trades.get(tradeid).then(async (tr) => {
			if (tr !== undefined) {
				tradeDrawerRef.current.openModal(tr);
			}
			else {
				toast({ title: 'Oops, trade not found ...', description: "Please open the account drawer and click 'Re-Sync Data'", status: 'info', position: 'top', duration: 2000, isClosable: false });
			}
		}).catch((e) => {
			toast({ title: 'Oops, error fetching trade ...', description: "Please refresh your browser window", status: 'info', position: 'top', duration: 2000, isClosable: false });
		})
	}

	const savetrade = async (trade: Trade) => {

		setLoading(true);

		delete trade.sync_due; //only used locally

		let str = trade.notes.replace(/(<([^>]+)>)/ig, '').trim();
		if (!str.length) {
			trade.notes = "";
		}
		trade.notes = trade.notes.trim();
		trade.last_uuid = muuid;

		await updateTrade(trade).then(async (r) => {
			trade.sync_due = r;
			await db.trades.put(trade, trade.tradeid).then(async () => {
				//await reprocessStats(defaultgroup.groupid);
			}).catch(error => { });
		});

		updateSandMonly();

	}


	const addStratToUpdate = (s: TradeStrategy) => {
		setUpdatedStrategies([...updatedStrategies, s]);
	}

	const addmisttoupdate = (m: { name: string; id: number }) => {
		setUpdatedMistakes([...updatedMistakes, m]);
	}

	const columns = [
		columnHelper.accessor('tradeid', {
			id: 'tradeid',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
				</Text>
			),
			cell: (info) => (
				<>
					{info.row.original.reviewed ?
						(
							<Button variant='outline' colorScheme={'green'} size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original.tradeid)} isDisabled={loading2} isLoading={loading}>
								Reviewed +
							</Button>
						) : (
							<Button variant='outline' size={'sm'} borderRadius='10px' onClick={() => showTrade(info.row.original.tradeid)} isDisabled={loading2} isLoading={loading}>
								<Icon color='secondaryGray.500' as={MdAdd} w='20px' h='20px' />
							</Button>
						)}
				</>
			)
		}),
		columnHelper.accessor('datetime', {
			id: 'date',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DATE
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Flex direction='column'>
						<Text color={textColor} fontSize='sm' fontWeight='700'>
							{dayjs(info.getValue()).format(dateformat)}
						</Text>
						<Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
							{"Duration: " + getDuration(info.row.original.duration)}
						</Text>
					</Flex>
				</Flex>
			)
		}),
		columnHelper.accessor('accountid', {
			id: 'accountid',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ACCOUNT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{getAccountName(info.getValue())}
				</Text>

			)
		}),
		columnHelper.accessor('ticker', {
			id: 'ticker',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TICKER
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('side', {
			id: 'side',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIDE
				</Text>
			),
			cell: (info) => (

				<Badge
					colorScheme={info.getValue().toLocaleLowerCase() === "sell" ? 'red' : 'green'}
					color={info.getValue().toLocaleLowerCase() === "sell" ? 'red.500' : 'green.500'}
					fontSize='sm'
					fontWeight='600'>
					{info.getValue().toUpperCase()}
				</Badge>
			)
		}),
		columnHelper.accessor('size', {
			id: 'size',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIZE
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('nett', {
			id: 'nett',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NETT
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
					{currencyformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('rvalue', {
			id: 'rvalue',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					R-VALUE
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={(getValue() !== 0 ? (getValue() > 0 ? (getValue() < 1 ? 'orange.500' : 'green.500') : 'red.500') : textColorSecondary)} fontSize='sm' fontWeight='600'>
					{getValue() !== 0 ? getValue().toFixed(2) : 'No stop'}
				</Text>
			)
		})

	];

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: tablesize,
	})
	const [columnVisibility, setColumnVisibility] = useState({});

	const [sorting, setSorting] = useState<SortingState>([{
		id: 'date',
		desc: true, // sort by name in descending order by default
	}]);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			pagination,
			columnVisibility
		},
		onColumnVisibilityChange: setColumnVisibility,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,

		debugTable: true
	});

	if (!data.length && !loading) {
		return <Flex justify={'center'} align={'center'}><ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
			<Box p='10px' color={textColorSecondary} mt='4'>
				<Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No {localStorage.getItem('dashflag') ? localStorage.getItem('dashflag').toLowerCase() : 'flagged'} trades to display</Text>
			</Box>
		</ScaleFade>
		</Flex>
	}
	else if (!data.length && (loading)) {
		return <Flex hidden={!loading} justify='center' padding='30px'>
			{/*<Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='xl' />*/}
		</Flex>
	}
	else {
		return (
			<>
				<Table variant='simple' color='gray.500'>
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {

									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
				<Divider mt='10px' hidden={table.getRowCount() <= 10} />
				<Flex direction={{ base: 'column', md: 'row' }} justify='center' hidden={table.getRowCount() <= 10}>
					<Flex direction='row' justify='center' hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
						<Text mt='23px' ms='20px' me='5px'>Page</Text>
						<Text as='b' mt='23px' ms='5px' me='20px'>{table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount().toLocaleString()}</Text>
						<Text mt='23px' color={textColorSecondary}>Go to page: </Text>
						<NumberInput ms='10px' mt='20px' size='sm' min={1} max={table.getPageCount()} w='85px' allowMouseWheel={true} hidden={table.getRowCount() <= table.getState().pagination.pageSize}
							onChange={(valueString) => table.setPageIndex(valueString ? Number(valueString) - 1 : 0)}
						>
							<NumberInputField color={textColor} borderRadius='5px' />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</Flex>
					<Flex direction='row' justify='center' ms='5px'>
						{/*<Select size='sm' mt='20px' ms='10px' me='10px' borderRadius='5px' alignContent='end' w='120px' value={tablesize}//table.getState().pagination.pageSize}
							onChange={e => { updatePageSize(Number(e.target.value)) }}
						>
							{[10, 20, 30, 40, 50, 100].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</Select>*/}
						<Flex hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowLeft />} onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowLeft />} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowRight />} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowRight />} onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
						</Flex>
					</Flex>
				</Flex>
				<TradeDrawer ref={tradeDrawerRef} savetrade={savetrade} bulkupdate={false} addUpdatedStrategy={addStratToUpdate} addUpdatedMistake={addmisttoupdate} updateSandMonly={updateSandMonly}></TradeDrawer>
			</>
		);
	}
}
