
import {
    useDisclosure,
    Button,
    Icon,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    useColorModeValue,
    Flex,
    InputGroup,
    InputLeftAddon,
    Input,
    Text,
    Box,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    useToast,
    Image,
    Heading,
    TabPanel,
    TabPanels,
    Tabs,
    TabList,
    Tab
}
    from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { MdOutlineImageNotSupported } from 'react-icons/md';
import { newTradeLogic, StrategyRule, Trade, TradeLogic, TradeStrategy } from 'views/trader/trades/variables/types';
import { currencyformat, getDuration, numberColor } from 'views/trader/trades/variables/FormatData';
import Card from "components/card/Card";
import FullScreenButton from "views/trader/components/FullScreenButton";
import NotesTemplates from "views/trader/trades/components/fields/NotesTemplates";
import RuleScore from "views/trader/trades/components/fields/RuleScore";
import dayjs from "dayjs";
import JornoQuill from "views/trader/components/inputs/JornoQuill";
import useTrade from "views/trader/trades/hooks/useNewTrade";
import MentorStrategyTags from "./MentorStrategyTags";
import MentorMistakeTags from "./MentorMistakeTags";
import ImageDrawer from "views/trader/trades/components/modals/ImageDrawer";
import JornoQuillReadOnly from "views/trader/components/inputs/JornoQuillReadOnly";
import StrategyRuleView from "views/trader/trades/components/fields/StrategyRuleView";
import MentorTradeModalExecutionsTable from "./MentorTradeModalExecutionsTable";
import api from "services/api";
import { useData } from "services/data/DataProvider";
import { useAuth } from "services/auth/AuthProvider";
import TradeLogicCard from "views/trader/trades/components/fields/TradeLogicCard";

const MentorTradeDrawer = forwardRef((props: { completereview: any, isStudent: boolean }, ref) => {

    const { completereview, isStudent } = props;

    const { muuid } = useAuth();
    const { dateformat } = useData();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [processing, setprocessing] = useState(false);
    const drawerBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('gray.700', 'white');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const imageColor = useColorModeValue('gray.300', 'gray.500');
    const headcol = useColorModeValue('gray.400', 'gray.500');
    const bgcolor = useColorModeValue('white', 'navy.800');
    const [trade, setTrade] = useState<Trade>(useTrade());
    const [tradelogic, setTradeLogic] = useState<TradeLogic>(newTradeLogic());
    const [tradeStrategies, setTradeStrategies] = useState<{ id: number, name: string, rules: StrategyRule[] }[]>([]);
    const [tradeMistakes, setTradeMistakes] = useState([]);
    const [notes, setNotes] = useState("");
    const [mentornotes, setMentornotes] = useState("");
    const [stoploss, setStoploss] = useState(null);
    const [target, setTarget] = useState(null);
    const [points, setPoints] = useState('');
    const [targetpoints, setTargetPoints] = useState('');
    const [rvalue, setRvalue] = useState('');
    const [targetRvalue, setTargetRvalue] = useState('');
    const [rvaluecol, setRvaluecol] = useState('grey.200');
    const [targetrvaluecol, setTragetRvaluecol] = useState('grey.200');
    const [nettPL, setnettPL] = useState(0);
    const [usedStrategyRules, setUsedStrategyRules] = useState<StrategyRule[]>([]);
    const [ruleshidden, setRuleshidden] = useState(true);
    const [rulescore, setRulescore] = useState(0);
    const [image, setImage] = useState<string>("");
    const toast = useToast();
    const imageRef = useRef(null);

    useImperativeHandle(ref, () => ({
        async openModal(trade: Trade) {
            await updateVariables(trade);
            onOpen();
        }
    }));

    const updateVariables = async (tr: Trade) => {
        setTrade(tr);
        setTradeLogic(tr.trade_logic.length ? tr.trade_logic[0] : newTradeLogic());
        setnettPL(tr.nett);
        setTradeStrategies(tr.strategies);
        updateUsedStrategyRules(tr.strategies);
        setRulescore(tr.rulescore);
        setTradeMistakes(tr.mistakes);
        setNotes(tr.notes);
        setMentornotes(tr.mentornotes)
        setStoploss(tr.stop_price === 0 ? null : tr.stop_price);
        setTarget(tr.target_price === 0 ? null : tr.target_price);
        setImage(tr.base64Image);
        setPoints(String(tr.points));
        updateRfactor(tr);
        updateTargetRvalue(tr);
        let tpoints = Math.abs((tr.target_price - tr.entry_avg) * tr.size);
        setTargetPoints(tr.target_price === 0 ? '' : String(tpoints));
    }

    const updateUsedStrategyRules = async (used: TradeStrategy[]) => {

        usedStrategyRules.splice(0, usedStrategyRules.length);// empty array first

        let r1: StrategyRule[] = [];

        for (let strat of used) {
            for (let rule of strat.rules) {
                r1.push(rule);
            }
        }

        updateStrategyRules(r1);
        setRuleshidden(!r1.length);
    }


    const updateStrategyRules = (arr: any[]) => {
        setUsedStrategyRules(arr);
        updateRuleScore(arr);
    }

    const updateRuleScore = (update: any[]) => {
        let u = update.filter((r) => r.used);
        let score = (update.length ? ((u.length / update.length) * 100) : 0)
        setRulescore(score);
        trade.rulescore = Number(score.toFixed());
    }

    const closeTrade = () => {
        setprocessing(false);
        onClose();
    }

    function formatCurrencyAmount(amt: number, currency: string) {
        return currencyformat(amt, currency);
    }

    const setTradeNotes = (content: string) => {
        updateTradeNotes(content);
    }

    const updateTradeNotes = (text: string) => {
        setMentornotes(text);
        trade.mentornotes = text;

    }

    const imageclick = () => {
        imageRef.current.openImage();
    }

    const savechanges = async () => {
        if (!isStudent) {
            setprocessing(true);

            let str = mentornotes.replace(/(<([^>]+)>)/ig, '').trim();
            let mnotes = (!str.length ? "" : mentornotes.trim());

            await api.post('/mentor/savenotes', { tradeid: trade.tradeid, mentornotes: mnotes, last_uuid: muuid }).then(async (res) => {
                //alert(res.data);
                if (res.data === true) {
                    toast({ title: 'Trade review saved', description: '', status: 'success', position: 'top', duration: 3000, isClosable: false })
                    completereview(trade);
                    closeTrade();
                }
                else {
                    toast({ title: 'Error updating trade ...', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
                    setprocessing(false);
                }
            }).catch(() => {
                setprocessing(false);
            })
        }
        else {
            completereview(trade);
            closeTrade();
        }
    }
    const updateRfactor = (tr: Trade) => {
        if (tr.stop_price > 0) {
            let stop_points;
            switch (tr.side) {
                case 'buy':
                    stop_points = tr.entry_avg - tr.stop_price;
                    break;
                case 'sell':
                    stop_points = tr.stop_price - tr.entry_avg;
                    break;
            }
            let R = (tr.points / tr.size) / stop_points;
            setRvaluecol(R > 0 ? (R < 1 ? 'orange.500' : 'green.500') : 'red.500');
            setRvalue(R.toFixed(2) + ' R')

        }
        else {
            setRvalue('');
        }
    }
    const updateTargetRvalue = (tr: Trade) => {
        if (tr.target_price > 0 && tr.stop_price > 0) {
            let stop_points = Math.abs(tr.entry_avg - tr.stop_price);
            let target_points = Math.abs(tr.target_price - tr.entry_avg);
            let R = (target_points / tr.size) / (stop_points / tr.size);
            trade.target_rvalue = Number(R.toFixed(2));
            setTragetRvaluecol(R > 0 ? (R < 1 ? 'orange.500' : 'green.500') : 'red.500');
            setTargetRvalue(R.toFixed(2) + ' R')
            setTargetPoints(String(target_points));
        }
        else {
            setTargetRvalue('');
            trade.target_rvalue = 0;
        }
    }

    return (
        <>
            <Drawer isOpen={isOpen} onClose={closeTrade} size='full' placement='top' >
                <DrawerOverlay />
                <DrawerContent bg={drawerBg}>
                    <DrawerCloseButton onClick={closeTrade} isDisabled={processing} />
                    <FullScreenButton />
                    <DrawerHeader>
                        <Text mt='5px'>Trade Details</Text>
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex justify={'space-between'} direction={{ base: 'column', md: 'row' }}>
                            <Box>
                                <InputGroup size='md'>
                                    <InputLeftAddon color='white' bg={numberColor(trade ? trade.nett : 0)} fontWeight={'700'}>
                                        {formatCurrencyAmount(nettPL, trade.currency)}
                                    </InputLeftAddon>
                                    <InputLeftAddon borderLeftRadius='0px'>
                                        {trade.ticker}
                                    </InputLeftAddon>
                                    <Input placeholder='trade date ...' defaultValue={trade ? dayjs(trade.datetime).format(dateformat) : ""} color={textColor} isReadOnly={true} />
                                </InputGroup>
                            </Box>
                            <Text color='secondaryGray.500' fontSize='md' fontWeight='600' mt={{ base: '20px', md: '8px' }} ms={{ base: '0px', md: '20px' }}>
                                {"Duration: " + getDuration(trade.duration)}
                            </Text>
                        </Flex>

                        <Grid w='100%' mt='40px' templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' }} gap={5}>
                            <GridItem colSpan={1} minH={'600px'}>

                                <Tabs variant='enclosed' isFitted >
                                    <TabList>
                                        <Tab><FormLabel fontWeight='bold' fontSize='sm' mb='8px'>LOGIC</FormLabel></Tab>
                                        <Tab><FormLabel fontWeight='bold' fontSize='sm' mb='8px'>RISK</FormLabel></Tab>
                                        <Tab><FormLabel fontWeight='bold' fontSize='sm' mb='8px'>STRATEGY</FormLabel></Tab>
                                        <Tab><FormLabel fontWeight='bold' fontSize='sm' mb='8px'>NOTES</FormLabel></Tab>
                                    </TabList>
                                    <TabPanels borderWidth={'1px'} borderBottomRadius={'20px'} minH={'550px'}>
                                        <TabPanel justifyItems={'center'}>
                                            <Flex justify={'center'} gap={5} mt='20px'>
                                                <TradeLogicCard tlogic={tradelogic} />
                                            </Flex>
                                        </TabPanel>
                                        <TabPanel>
                                            <Flex direction={'column'} justify={'space-between'} maxW={'750px'} mt='20px'>
                                                <Box justifyContent={'center'}>
                                                    <Flex direction={'row'} justify={'space-between'} gap={5}>
                                                        <FormControl>
                                                            <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>STOP price</FormLabel>
                                                            <Input color={textColor} isReadOnly={true} value={stoploss} />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>TARGET price</FormLabel>
                                                            <Input color={textColor} isReadOnly={true} value={target} />
                                                        </FormControl>
                                                    </Flex>

                                                    <Flex direction={'column'} justify={'space-between'} mt='30px' gap={5}>
                                                        <Flex direction={'column'}>
                                                            <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' py='15px'>
                                                                <Flex><Heading fontWeight='bold' size={'xs'} mt='-25px' ms='-11px' bg={bgcolor} px='10px' py='0px'>Points (total)</Heading></Flex>
                                                                <Flex direction={'row'} justify={'space-between'} gap={2}>
                                                                    <Flex direction={'column'} >
                                                                        <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Target</FormLabel>
                                                                        <Input fontWeight={'bold'} color={Number(targetpoints) < 0 ? 'red.500' : 'green.500'} isReadOnly={true} value={targetpoints}></Input>
                                                                    </Flex>
                                                                    <Flex direction={'column'} >
                                                                        <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Realised</FormLabel>
                                                                        <Input fontWeight={'bold'} color={Number(points) < 0 ? 'red.500' : 'green.500'} isReadOnly={true} value={points}></Input>
                                                                    </Flex>
                                                                </Flex>
                                                            </Card>
                                                        </Flex>
                                                        <Flex direction={'column'}>
                                                            <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' py='15px'>
                                                                <Flex><Heading fontWeight='bold' size={'xs'} mt='-25px' ms='-11px' bg={bgcolor} px='10px'>R-Value</Heading></Flex>
                                                                <Flex direction={'row'} justify={'space-between'} gap={2}>
                                                                    <Flex direction={'column'}>
                                                                        <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Target</FormLabel>
                                                                        <Input fontWeight={'bold'} color={targetrvaluecol} isReadOnly={true} value={targetRvalue}></Input>
                                                                    </Flex>
                                                                    <Flex direction={'column'}>
                                                                        <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Realised</FormLabel>
                                                                        <Input fontWeight={'bold'} color={rvaluecol} isReadOnly={true} value={rvalue}></Input>
                                                                    </Flex>
                                                                </Flex>
                                                            </Card>
                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </TabPanel>

                                        <TabPanel>
                                            <Flex direction={'column'} mt='20px'>
                                                <Grid templateColumns='repeat(2, 1fr)' gap={5}>
                                                    <GridItem>
                                                        <MentorStrategyTags
                                                            id='strategies'
                                                            label='Strategies'
                                                            mb='0px'
                                                            h='auto'
                                                            usedstrategies={tradeStrategies}
                                                        /></GridItem>
                                                    <GridItem>
                                                        <MentorMistakeTags
                                                            id='mistake'
                                                            label='Mistakes'
                                                            mb='0px'
                                                            h='auto'
                                                            usedMistakeTags={tradeMistakes}
                                                        />
                                                    </GridItem>
                                                </Grid>
                                                <Flex hidden={ruleshidden} direction={'column'}>
                                                    <Flex direction={'row'} justify={'space-between'}>
                                                        <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Rules followed</FormLabel>
                                                        <RuleScore rulescore={rulescore} width={'30%'} size={'lg'} />
                                                    </Flex>
                                                    <Card borderColor={bdColor} borderWidth='1px' borderRadius={'10px'} mb={'20px'}>
                                                        {usedStrategyRules.map((rule, index) => (
                                                            <StrategyRuleView rule={rule} />
                                                        ))}
                                                    </Card>
                                                </Flex>
                                            </Flex>
                                        </TabPanel>

                                        <TabPanel>
                                            <Flex direction={'column'} mt='20px'>
                                                <FormLabel fontWeight='bold' fontSize='sm' mt='5px'>Trader Notes</FormLabel>
                                                <Flex direction={{ base: 'column', md: 'row' }} gap={5}>
                                                    <Box flex='1'>
                                                        {notes.trim().length ? (
                                                            <JornoQuillReadOnly content={notes} placeHolder={'No notes entered by trader'} />
                                                        ) : (
                                                            <Heading size={'xs'} color={headcol}>No notes entered by trader</Heading>
                                                        )}
                                                    </Box>
                                                </Flex>
                                                {!isStudent &&
                                                    <>
                                                        <Flex direction={'row'} justify={'space-between'} align={'center'} mt='20px'>
                                                            <Flex direction={'row'}>
                                                                <FormLabel fontWeight='bold' fontSize='sm' mt='5px'>Mentor Notes</FormLabel>
                                                            </Flex>
                                                            <NotesTemplates type="mentor" notes={mentornotes} setnotes={updateTradeNotes} reset={''} mb={'8px'} size={'sm'} />
                                                        </Flex>
                                                        <Box>
                                                            <JornoQuill content={mentornotes} updatecontent={setTradeNotes} allowimages={false} placeHolder={'Enter mentor notes ...'} />
                                                        </Box>
                                                    </>
                                                }
                                            </Flex>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>

                            </GridItem>
                            <GridItem colSpan={{ base: 1, md: 2, lg: 3 }} minH={'600px'}>
                                <Card borderColor={bdColor} borderWidth='1px' h='100%'>
                                    <Flex direction={'row'} justify={'space-between'}>
                                        <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Image</FormLabel>
                                    </Flex>
                                    {image.length ? (
                                        <>
                                            <Flex w="100%" h="100%" justify="center" >
                                                <Flex onClick={() => imageclick()}>
                                                    <Image src={image} w="100%" maxH={'700px'} cursor="pointer" />
                                                </Flex>
                                            </Flex>
                                            <ImageDrawer ref={imageRef} imageurl={image}></ImageDrawer>
                                        </>
                                    ) : (
                                        <Flex w="100%" h="100%" direction={'column'} justify="center" align={'center'}>
                                            <Icon as={MdOutlineImageNotSupported} w='80px' h='80px' mb='20px' color={imageColor} />
                                            <Text
                                                mb='12px'
                                                fontSize='md'
                                                fontWeight='700'
                                                color={imageColor}>
                                                No image saved by trader
                                            </Text>
                                        </Flex>
                                    )}
                                </Card>
                            </GridItem>
                        </Grid>

                        <Flex mt={{ base: '20px', lg: '20px' }}>
                            <Card borderColor={bdColor} borderWidth='1px' minH={'300px'} maxW='1100px' >
                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Executions</FormLabel>
                                <MentorTradeModalExecutionsTable trade={trade} />
                            </Card>
                        </Flex>
                    </DrawerBody>
                    <DrawerFooter>
                        <Flex direction={'row'} justify={'end'} mt='20px'>
                            <Button variant={'ghost'} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' ms={3} isLoading={processing} onClick={savechanges} loadingText='Saving'>
                                {isStudent ? 'Close' : 'Save Review'}
                            </Button>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
});

export default MentorTradeDrawer;
