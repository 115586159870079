/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Card, Flex, FormLabel, Grid, GridItem, Select, Show, useColorModeValue, useToast, Text, Icon } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { db } from 'services/db';

// Custom components
import StatBar from 'views/trader/components/display/StatBar';
import StatBarLarge from '../components/display/StatBarLarge';
import { SyncAPI } from '../components/syncapi/SyncApi';
import DashCalendar from './components/calendar/DashCalendar';
import DashCharts from './components/stats/DashCharts';
import DashStats from './components/stats/DashStats';
import TradesTableDash from '../trades/components/tables/TradesTableDash';
import { useAuth } from 'services/auth/AuthProvider';
import { MdOutlinePermIdentity } from 'react-icons/md';
import { useLoading } from '../../../services/loading/LoadingProvider';
import { useData } from 'services/data/DataProvider';


export default function Default() {

  const { user, setUser, IsMentored } = useAuth();//useContext(AuthContext);
  const { updateFlaggedTrades } = useData();

  const { setLoading } = useLoading();//useState(false);
  const accicon = <Icon as={MdOutlinePermIdentity} boxSize={7} mt='-5px'></Icon>;
  const [tabletitle, settabletitle] = useState(localStorage.getItem('dashflag') ? localStorage.getItem('dashflag') : 'Flagged')
  const bdColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();

  useEffect(() => {

    if (!localStorage.getItem('initmfa')) {
      toast({ icon: accicon, title: "Hi " + user.username.toUpperCase() + " .... welcome!", description: <Text whiteSpace="pre-line">{"\nWe STRONGLY urge you to enable 2-factor authentication to further secure your profile.\n\nClick the user icon on the top right to open account settings and click the 2-FA button to setup .... eezy peezy!\n\n"}</Text>, status: 'info', position: 'top', duration: null, isClosable: true })

      localStorage.setItem('initmfa', 'true');
    }

    if (!IsMentored) {
      settabletitle('Flagged');
      localStorage.removeItem('dashflag')
    }

    setLoading(true);

    SyncAPI(user.id).then(async (res) => {
      setLoading(false);
      await db.users.get(user.id).then((u) => {
        setUser(u);
      })
    });
  }, [])

  const updateflagged = async (e: ChangeEvent<HTMLSelectElement>) => {
    settabletitle(e.target.value);
    localStorage.setItem('dashflag', e.target.value);
    updateFlaggedTrades(e.target.value);

  }

  return (
    <>
      <Flex direction="column" pt={{ base: '130px', md: '80px', xl: '80px' }} >
        <Box w="100%" mb="20px">
          <Show breakpoint='(min-width: 2400px)'>
            <StatBarLarge ></StatBarLarge>
          </Show>
          <Show breakpoint='(max-width: 2399px)'>
            <StatBar />
          </Show>
        </Box>
        <Grid
          templateAreas={{
            base: `"stats" "calendar" "trades" "charts" `,
            lg: `"stats calendar" "trades charts"`,
          }}
          gridTemplateRows='repeat(1, 1fr)'
          gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
          gap={5}
        >
          <GridItem area={'stats'}>
            <DashStats />
          </GridItem>
          <GridItem h='100%' area={'calendar'}>
            <DashCalendar></DashCalendar>
          </GridItem>
          <GridItem area={'trades'} overflowX={'auto'}>
            <Card borderWidth={'1px'} borderRadius={'20px'} borderColor={bdColor} bg='transparent' p='20px'>
              <Flex mb='20px' direction={'row'} justify={'space-between'}>
                <FormLabel fontWeight='bold' fontSize='md'>{tabletitle} trades</FormLabel>
                {IsMentored &&
                  <Select size={'sm'} w='150px' borderRadius={'5px'} onChange={updateflagged} value={tabletitle}>
                    <option value='Flagged'>Flagged</option>
                    <option value='Reviewed'>Reviewed</option>
                  </Select>
                }
              </Flex>
              <TradesTableDash />
            </Card>
          </GridItem>
          <GridItem area={'charts'}>
            <DashCharts />
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
}
