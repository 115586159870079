import dayjs from 'dayjs';
import { db } from 'services/db';
import { AccountExecutions, newExecution } from 'views/trader/trades/variables/types';
import Papa from 'papaparse';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export function getTopstepXTrades(f: File): Promise<AccountExecutions[][]> {

    dayjs.extend(utc)
    dayjs.extend(timezone);

    return new Promise(async (resolve, reject) => {

        try {

            const accountarr: AccountExecutions[][] = [[], []];
            //let instruments = await db.instruments.toArray();

            Papa.parse(f, {
                header: true,
                download: true,
                skipEmptyLines: true,
                delimiter: ",",
                complete: async (result: any) => {

                    try {
                        for (let e of result.data) {

                            if (e.Status.toString().trim() === "Filled") {

                                const ex = newExecution();

                                let contract = e.ContractName.trim();
                                ex.ticker = contract.substring(0, contract.length-2);

                                let inst = await db.instruments.where("ticker").equalsIgnoreCase(ex.ticker).toArray();

                                if (inst.length) {
                                    let d = new Date(e.FilledAt)
                                    let djs = dayjs(d).tz(inst[0].timezone);
                                    ex.datetime = djs.format('YYYY-MM-DD HH:mm:ss');
                                    ex.instrument = inst[0].category;
                                    ex.currency = inst[0].currency;
                                    ex.side = e.Side.trim().toLowerCase() === 'ask' ? 'sell' : 'buy';
                                    ex.size = Number(e.Size);
                                    ex.price = Number(e.ExecutePrice);
                                    ex.fee = 0;
                                    ex.contract = contract;
                                    ex.orderid = e.Id.toString().trim();

                                    const acc = accountarr[0].find((a) => (a.account === e.AccountName));
                                    if (acc) {
                                        acc.executions.push(ex);
                                    }
                                    else {
                                        let a: AccountExecutions = { account: e.AccountName, executions: [] };
                                        a.executions.push(ex);
                                        accountarr[0].push(a);
                                    }
                                }
                                else {
                                    let d = new Date(e.FilledAt)
                                    let djs = dayjs(d).tz("America/New_York")
                                    ex.datetime = djs.format('YYYY-MM-DD HH:mm:ss');
                                    ex.instrument = '';
                                    ex.currency = 'USD'
                                    ex.side = e.Side.trim().toLowerCase() === 'ask' ? 'sell' : 'buy';
                                    ex.size = Number(e.Size);
                                    ex.price = Number(e.ExecutePrice);
                                    ex.fee = 0;
                                    ex.contract = contract;
                                    ex.orderid = e.Id.toString().trim();

                                    const acc = accountarr[1].find((a) => (a.account === e.AccountName));
                                    if (acc) {
                                        acc.executions.push(ex);
                                    }
                                    else {
                                        let a: AccountExecutions = { account: e.AccountName, executions: [] };
                                        a.executions.push(ex);
                                        accountarr[1].push(a);
                                    }
                                }
                            }
                        }
                        resolve(accountarr);
                    }
                    catch (err) {
                        alert(JSON.stringify(err))
                        reject(err);
                    }
                },
            })


        }
        catch (err) {
            alert(JSON.stringify(err))
            reject(err);
        }
    });
}


