/* eslint-disable */

import {
    Flex, Table, Tbody, Td, Text,
    Th, Thead, Tr, useColorModeValue, Divider, IconButton,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
    Select,
    Spinner,
    Box,
    ScaleFade,
    Button,
    Icon,
    useToast,
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    SortingState,
    PaginationState,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    FilterFn
} from '@tanstack/react-table';
// Custom components  
//import TradeModal from '../modals/TradeModal';

import { AccountTableRow, Trade } from 'views/trader/trades/variables/types';
import { useEffect, useRef, useState } from 'react';
import { MdInsights, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';

import { currencyformat, numberColor } from 'views/trader/trades/variables/FormatData';
import DeleteAccountConfirm from 'views/trader/components/confirm/DeleteAccountConfirm';
import AccountEditModal from './AccountEditModal';
import ShowTradesModal from 'views/trader/components/ShowTradesModal';
import { useData } from 'services/data/DataProvider';
import AccountSelect from './AccountSelect';
import { useLoading } from 'services/loading/LoadingProvider';



const columnHelper = createColumnHelper<AccountTableRow>();

export default function AccountsTable(props: { rows: AccountTableRow[] }) {

    const [data, setData] = useState<AccountTableRow[]>([]);
    const { loading } = useLoading();

    const [sorting, setSorting] = useState<SortingState>([]);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [tablesize, setTablesize] = useState(localStorage.getItem('tablesize') ? Number(localStorage.getItem('tablesize')) : 10);
    const { usertrades } = useData();
    const toast = useToast();

    const tradesRef = useRef(null);


    useEffect(() => {
        setData(props.rows);
    }, [props.rows])

    const showTrades = (accountid: number) => {

        let tr = usertrades.filter((t: Trade) => t.accountid === accountid);

        if (!tr.length) {
            toast({ title: 'No trades in this account yet ...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false });
            return;
        }

        let acc = props.rows.find((a) => a.accountid === accountid);

        if (tradesRef) {
            tradesRef.current.openModal(tr, acc.accountname, '');
        }

    }

    const columns = [
        columnHelper.accessor('order', {
            id: 'order',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    #
                </Text>
            ),
            cell: (info: any) => (
                <Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
            )
        }),
        columnHelper.accessor('accountname', {
            id: 'accountname',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Account
                </Text>
            ),
            cell: (info: any) => (
                <Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
            )
        }),
        columnHelper.accessor('groupname', {
            id: 'groupname',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Group
                </Text>
            ),
            cell: (info: any) => (
                <Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
            )
        }),
        columnHelper.accessor('trades', {
            id: 'trades',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Trades
                </Text>
            ),
            cell: (info: any) => (
                <Button size={'sm'} onClick={() => showTrades(info.row.original.accountid)} colorScheme={'blue'}>
                    <Icon as={MdInsights} boxSize={5} me='10px'></Icon>
                    {info.getValue()}
                </Button>
            )
        })
        ,
        columnHelper.accessor('equity', {
            id: 'equity',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Equity
                </Text>
            ),
            cell: ({ row, getValue }) => (
                <Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
                    {currencyformat(getValue(), row.original.currency)}
                </Text>
            )
        }),
        columnHelper.accessor('change', {
            id: 'change',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Nett P/L
                </Text>
            ),
            cell: ({ row, getValue }) => (
                <Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
                    {currencyformat(getValue(), row.original.currency)}
                </Text>
            )
        }),
        columnHelper.accessor('percentage', {
            id: 'percentage',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    % Change
                </Text>
            ),
            cell: ({ row, getValue }) => (
                <Text color={numberColor(getValue())} fontSize='sm' fontWeight='600'>
                    {Number(getValue()).toFixed(2) + "%"}
                </Text>
            )
        }),
        columnHelper.accessor('accountid', {
            id: 'accountmange',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                </Text>
            ),
            cell: (info) => (
                <>
                    <Flex direction={'row'} gap={2}>
                        <AccountEditModal accountid={info.row.original.accountid} />
                        <DeleteAccountConfirm accountid={info.row.original.accountid} resetEdit={() => setAccountEdit(false)} table={true} />
                        <AccountSelect accountid={info.row.original.accountid} />
                    </Flex>
                </>
            )
        }),

    ];

    const updatePageSize = (size: number) => {
        setTablesize(size);
        localStorage.setItem('tablesize', size.toFixed());
        table.setPageSize(size);
    }
    const globalFilterFn: FilterFn<any> = (row, columnId, filterValue: any) => {
        let value = row.getValue(columnId) as string;
        if (typeof value === 'number') value = String(value);
        return String(value).toLowerCase().includes(filterValue);
    };

    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: tablesize,
    })
    const [columnVisibility, setColumnVisibility] = useState({});
    const [globalFilter, setGlobalFilter] = useState<any>([])

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            columnVisibility,
            globalFilter,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        globalFilterFn: globalFilterFn,
        debugTable: true
    });

    if (!data.length && (loading)) {
        return <Flex hidden={!loading} justify='center' padding='30px'>
            <Spinner thickness='4px' speed='0.65s' size='xl' />
        </Flex>
    }
    else if (!data.length) {
        return <Flex justify={'center'} align={'center'}><ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
            <Box p='10px' color={textColorSecondary} mt='4' textAlign={'center'}>
                <Text color={textColorSecondary} fontWeight={'700'} fontSize='md' mt='20px' mb='40px' ms='20px'>No accounts to display</Text>
                <Text color={'orange.500'} fontSize='md' mt='20px' mb='40px' ms='20px' fontWeight={'700'}>NOTE: platform/broker accounts added automatically when importing statements</Text>
            </Box>
        </ScaleFade>
        </Flex>
    }
    else {
        return (
            <>
                <Table variant='simple' color='gray.500' mt="12px">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {

                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe='10px'
                                            borderColor={borderColor}
                                            cursor='pointer'
                                            onClick={header.column.getToggleSortingHandler()}>
                                            <Flex
                                                justifyContent='space-between'
                                                align='center'
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color='gray.400'>
                                                {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{ sm: '14px' }}
                                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                                borderColor='transparent'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                <Divider mt='10px' hidden={table.getRowCount() <= 10} />
                <Flex direction={{ base: 'column', md: 'row' }} justify='center' hidden={table.getRowCount() <= 10}>
                    <Flex direction='row' justify='center' hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
                        <Text mt='23px' ms='20px' me='5px'>Page</Text>
                        <Text as='b' mt='23px' ms='5px' me='20px'>{table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount().toLocaleString()}</Text>
                        <Text mt='23px' color={textColorSecondary}>Go to page: </Text>
                        <NumberInput ms='10px' mt='20px' size='sm' min={1} max={table.getPageCount()} w='85px' allowMouseWheel={true} hidden={table.getRowCount() <= table.getState().pagination.pageSize}
                            onChange={(valueString) => table.setPageIndex(valueString ? Number(valueString) - 1 : 0)}
                        >
                            <NumberInputField color={textColor} borderRadius='5px' />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Flex>
                    <Flex direction='row' justify='center'>
                        <Select size='sm' mt='20px' ms='10px' me='10px' borderRadius='5px' alignContent='end' w='120px' value={tablesize}//table.getState().pagination.pageSize}
                            onChange={e => { updatePageSize(Number(e.target.value)) }}
                        >
                            {[10, 20, 30, 40, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Select>
                        <Flex hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
                            <IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowLeft />} onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
                            <IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowLeft />} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
                            <IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowRight />} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
                            <IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowRight />} onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
                        </Flex>
                    </Flex>
                    <ShowTradesModal ref={tradesRef} />
                </Flex>
            </>
        );
    }
}
function setAccountEdit(arg0: boolean) {
    //placeholder function
}

