import { Flex, FormLabel, Grid, GridItem } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card'
import { useEffect, useRef, useState } from 'react';
import InstrumentsChart from 'views/trader/dashboard/components/charts/InstrumentsChart';
import SideStatDonut from 'views/trader/dashboard/components/charts/SideStatDonut';
import { DashStatsData, maxdrawdown } from 'views/trader/dashboard/variables/DashStatsData';
import { StatInstruments, StatLose, StatScore, StatSide, StatSummary, StatWin, Trade } from 'views/trader/trades/variables/types';
import LoseStats from './components/LoseStats';
import SummaryStats from './components/SummaryStats';
import WinStats from './components/WinStats';
import ShowTradesModal from 'views/trader/components/ShowTradesModal';
import { useData } from 'services/data/DataProvider';
import JornoScoreChartRadar from '../charts/JornoScoreChartRadar';
import JornoScoreChartPolar from '../charts/JornoScoreChartPolar';
import WinLossStats from './components/WinLossStats';

const DashStats = () => {

  const { periodtrades, defaultcurrency, defaultaccount, groupAccounts, perfchart, targets } = useData();

  const bdColor = useColorModeValue('gray.200', 'gray.700');

  const [loading, setLoading] = useState(false);

  const [summary, setSummary] = useState<StatSummary>({ total_R: 0, avg_R: 0, avg_R_target: 0, profit_factor: 0, avg_Rulescore: 0, avg_trades: 0, max_strategy: "", max_mistake: "" });
  const [winners, setWinners] = useState<StatWin>({ wintotal: 0, winperc: 0, windays: 0, maxwin: 0, avgwin: 0, winstreak: 0, avg_duration: 0, bestday: 0 });
  const [losers, setLosers] = useState<StatLose>({ losstotal: 0, lossperc: 0, lossdays: 0, maxloss: 0, avgloss: 0, losstreak: 0, avg_duration: 0, worstday: 0 });
  const [sides, setSides] = useState<StatSide>({ long: 0, short: 0 })
  const [scores, setScores] = useState<StatScore>({ profit_factor: 0, pl_ratio: 0, avg_R_real: 0, avg_R_target: 0, winperc: 0, wlratio: 0, drawdown_max: 0 })
  const [instruments, setInstruments] = useState<StatInstruments>({ labels: [], data: [] });
  //const [trades, setTrades] = useState([]);

  const tradesModalRef = useRef(null);

  useEffect(() => {
    if (periodtrades.length>0) {
      updateVariables();
    }
    else{
      setSummary({ total_R: 0, avg_R: 0, avg_R_target: 0, profit_factor: 0, avg_Rulescore: 0, avg_trades: 0, max_strategy: "", max_mistake: "" });
      setWinners({ wintotal: 0, winperc: 0, windays: 0, maxwin: 0, avgwin: 0, winstreak: 0, avg_duration: 0, bestday: 0 });
      setLosers({ losstotal: 0, lossperc: 0, lossdays: 0, maxloss: 0, avgloss: 0, losstreak: 0, avg_duration: 0, worstday: 0 });
      setSides({ long: 0, short: 0 });
      setInstruments({ labels: [], data: [] });
      setScores({ profit_factor: 0, pl_ratio: 0, avg_R_real: 0, avg_R_target: 0, winperc: 0, wlratio: 0, drawdown_max: 0 })
    }
  }, [periodtrades, targets])

  const updateVariables = async () => {
    setLoading(true);
    await DashStatsData(periodtrades, defaultcurrency, targets).then(async (res) => {
      setSummary(res.summary);
      setWinners(res.winners);
      setLosers(res.losers);
      setSides(res.sides);
      setInstruments(res.instruments);

      await maxdrawdown(periodtrades, (defaultaccount ? [defaultaccount] : groupAccounts), defaultcurrency).then(async (d) => {
        res.scores.drawdown_max = Math.abs(d);
      }).catch(() => { })

      setScores(res.scores);

    })

    setLoading(false);
  }

  const showInstrumentTrades = (ticker: string) => {
    let tr = periodtrades.filter((t: Trade) => t.ticker === ticker);
    tradesModalRef.current.openModal(tr, ("TICKER: " + ticker), 'ticker');
  }

  const showSideTrades = (side: string) => {
    if (periodtrades.length) {
      let tr = periodtrades.filter((t: Trade) => t.side === side);
      let title = (side === 'buy' ? "LONG Trades" : "SHORT Trades")
      tradesModalRef.current.openModal(tr, title, side.toUpperCase());
    }
  }

  return (
    <>
      <Card borderColor={bdColor} borderWidth='1px' h='100%' bg='transparent'>
        <FormLabel fontWeight='bold' fontSize='md' mb='8px'>Performance Analytics</FormLabel>
        <Grid mt='10px' mb='20px' templateRows='repeat(1, 1fr)' templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={5}>
          <GridItem rowSpan={1} colSpan={1} >
            <SummaryStats loading={loading} stats={summary} currency={defaultcurrency} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={2}  >
            <WinLossStats loading={loading} winners={winners} losers={losers} currency={defaultcurrency} />
          </GridItem>
          {/*<GridItem rowSpan={1} colSpan={1} >
            <WinStats loading={loading} stats={winners} currency={defaultcurrency} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}  >
            <LoseStats loading={loading} stats={losers} currency={defaultcurrency} />
          </GridItem>*/}
          <GridItem rowSpan={1} colSpan={{ base: 1, md: 3 }}>
            <Flex direction={{ base: 'column', md: 'row' }} gap={5} height={'100%'}>
              {perfchart === 1 ? (
                <JornoScoreChartRadar scores={scores} />
              ) : (
                <JornoScoreChartPolar scores={scores} />
              )}
              <SideStatDonut stats={sides} showtrades={showSideTrades} />
              <InstrumentsChart stats={instruments} currency={defaultcurrency} showtrades={showInstrumentTrades} />
            </Flex>
          </GridItem>
        </Grid>
      </Card>
      <ShowTradesModal ref={tradesModalRef} />
    </>
  )
}

export default DashStats