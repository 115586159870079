
import Chart from 'react-apexcharts';
import { Box, Flex, Heading, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { StatInstruments } from 'views/trader/trades/variables/types'
import { ApexOptions } from 'apexcharts';
import Card from 'components/card/Card';
import { currencyformat } from 'views/trader/trades/variables/FormatData';
import { useData } from 'services/data/DataProvider';

const InstrumentsChart = (props: { stats: StatInstruments, currency: string, showtrades: any }) => {

    const { stats, currency, showtrades } = props;
    const { periodtrades } = useData();
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const bgcolor = useColorModeValue('white', 'navy.900');

    useEffect(() => {
        updateVariables();
    }, [stats, textColor])

    const updateVariables = () => {
        let data = [];
        let axis = [];
        for (let d of stats.data) {
            data.push(d.nett.toFixed(2))
            axis.push(d.ticker + " : " + currencyformat(d.nett, currency));
        }

        options.xaxis.categories = axis;
        options.dataLabels.background.foreColor = textColor;
        setVariables({ options: options, series: [{ data: data }] });
    }

    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            events: {
                click: function (event, chartContext, config) {
                    if (config.dataPointIndex > -1) {
                        showtrades(stats.labels[config.dataPointIndex]);
                    }
                }
            }

        },
        plotOptions: {
            bar: {
                barHeight: 40,
                distributed: false,
                horizontal: true,
                borderRadius: 8,
                dataLabels: {
                    position: 'bottom'
                },
                colors: { ranges: [{ from: 0, to: 1000000, color: '#01B574' }, { from: -1000000, to: 0, color: '#EE5D50' }] }
            }
        },
        colors: ['#dc748b', '#d8637c', '#d4526e', '#be4963'],
        grid: { show: false },
        legend: { show: false },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.15,
                opacityFrom: 0.9,
                opacityTo: 1
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex];
            },
            offsetX: 4,
            background: {
                enabled: true,
                foreColor: textColor,
                padding: 4,
                borderRadius: 2,
                borderWidth: 8,
                opacity: 0.2,
                dropShadow: {
                    enabled: false
                }
            }
        },
        stroke: {
            width: 0
        },
        xaxis: {
            categories: [],
            labels: {
                show: true,
                style: {
                    colors: ['#848287'],
                    fontSize: '12px',
                    fontFamily: 'DM Sans',
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: true
            },
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return currencyformat(value, currency)
                },
                title: {
                    formatter: () => "Nett: ",
                },
            }
        }
    }
    const [variables, setVariables] = useState({ options: options, series: [] });


    return (
        <>
            <Card h='100%' borderWidth='1px' bg='transparent' mt='10px'>
                <Flex>
                    <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Instruments</Heading>
                </Flex>

                {periodtrades.length < 1 ? (
                    <Flex justify={'center'} align={'center'} >
                        <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                            <Box p='10px' color={textColorSecondary} >
                                <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                            </Box>
                        </ScaleFade>
                    </Flex>
                ) : (
                    <Chart
                        type='bar'
                        options={variables.options}
                        width="100%"
                        height="100%"
                        series={variables.series}
                    />
                )}
            </Card>
        </>
    )
}

export default InstrumentsChart