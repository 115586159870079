import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, IconButton, useColorModeValue, Flex, Heading, Text, NumberInput, NumberInputField, NumberDecrementStepper, NumberIncrementStepper, NumberInputStepper, FormLabel, InputLeftAddon, InputGroup, Box, Radio, RadioGroup, Stack, Checkbox, Icon } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { ChangeEvent, useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { FaCircle } from 'react-icons/fa6'
import { IoSettingsOutline } from 'react-icons/io5'
import { PiChartPolarFill } from 'react-icons/pi'
import { TbChartPie, TbPolygon, TbRadar } from 'react-icons/tb'
import { useData } from 'services/data/DataProvider'

const JornoScoreSettings = (props: { isbutton: boolean }) => {

    const { isbutton } = props;

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { targets, updatetargets, perfchart, updatePerfchart } = useData();

    const bgcol = useColorModeValue('white', 'navy.900');
    const headcol = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.600', 'gray.300');

    const [pfactor, setPfactor] = useState(2);
    const [plratio, setPlRatio] = useState(2);
    const [wlratio, setWlRatio] = useState(2);
    const [rtarget, setRtarget] = useState(2);

    const [targetchk, setTargetChk] = useState(localStorage.getItem('targetchk') ? JSON.parse(localStorage.getItem('targetchk')) : true);

    useEffect(() => {
        setTargets();
        setTargetChk(localStorage.getItem('targetchk') ? JSON.parse(localStorage.getItem('targetchk')) : true);
    }, [targets])

    const setTargets = () => {
        if (targets.length) {
            setPfactor(targets[0])
            setPlRatio(targets[1])
            setWlRatio(targets[2])
            setRtarget(targets[3])
        }
    }

    const updateTargets = () => {
        updatetargets([pfactor, plratio, wlratio, rtarget]);
        onClose();
    }

    const updatevalue = (value: number, index: number) => {
        switch (index) {
            case 0: setPfactor(value); break;
            case 1: setPlRatio(value); break;
            case 2: setWlRatio(value); break;
            case 3: setRtarget(value); break;
        }
    }
    const resetdefault = () => {
        setPfactor(2)
        setPlRatio(2)
        setWlRatio(2)
        setRtarget(2)
    }

    const updateType = (e: string) => {
        updatePerfchart(Number(e))
    }

    const updatetargetchk = (e: ChangeEvent<HTMLInputElement>) => {
        setTargetChk(e.target.checked);
        localStorage.setItem('targetchk', JSON.stringify(e.target.checked));
    }

    return (

        <>
            {isbutton === true ? (
                <Button variant='outline' onClick={onOpen} mb='20px' w={'100%'}>
                    <Icon as={IoSettingsOutline} h='20px' w='20px' me='10px'></Icon>
                    Performance targets
                </Button>
            ) : (
                <IconButton zIndex={1000} mt='-40px' bg={bgcol} variant={'solid'} size={'md'} aria-label={'scoresett'} borderRadius={'10px'} onClick={onOpen}><IoSettingsOutline /></IconButton>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: '2xl' }} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Performance targets</ModalHeader>
                    <ModalCloseButton onClick={() => { setTargets() }} />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Flex justify={'center'} mb='30px'>
                                <FormLabel color={'red.500'} fontWeight={700}>NOTE: scores out of 100 are calculated as explained below</FormLabel>
                            </Flex>
                            <Card h='100%' borderWidth='2px' bg='transparent' mt='10px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <Heading size={'xs'} px='10px'>DASHBOARD CHART TYPE</Heading>
                                    </Flex>
                                </Box>
                                <RadioGroup defaultValue={perfchart.toFixed()} onChange={updateType}>
                                    <Flex direction='row' justify={'space-evenly'} align={'center'}>
                                        <Flex direction='row' justify={'space-evenly'}>
                                            <TbPolygon size={30} />
                                            <Radio colorScheme='blue' value='1' ms='20px'>
                                                RADAR
                                            </Radio>
                                        </Flex>
                                        <Flex direction='row' justify={'space-evenly'}>
                                            <TbChartPie size={30} />
                                            <Radio colorScheme='blue' value='2' ms='20px'>
                                                POLAR AREA
                                            </Radio>
                                        </Flex>
                                    </Flex>
                                </RadioGroup>
                            </Card>


                            <Card h='100%' borderWidth='2px' borderColor={'#8f00ff'} bg='transparent' mt='30px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} borderColor={'#8f00ff'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <FaCircle color='#8f00ff' />
                                        <Heading size={'xs'} px='10px'>Profit factor</Heading>
                                    </Flex>
                                </Box>
                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} align={'center'}>
                                    <Flex direction={'column'} mb={{ base: '20px', md: '0px' }}>
                                        <Text>Ratio of nett TOTAL WIN vs TOTAL LOSS</Text>
                                        <Text mt='10px' fontWeight={700}>Score = actual as percentage of target</Text>
                                    </Flex>

                                    <Flex maxW={'200px'}>
                                        <InputGroup>
                                            <InputLeftAddon>Target</InputLeftAddon>
                                            <NumberInput allowMouseWheel min={1} step={0.1} value={pfactor} onChange={(e) => updatevalue(Number(e), 0)}>
                                                <NumberInputField color={textColor} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </InputGroup>
                                    </Flex>
                                </Flex>
                            </Card>
                            <Card h='100%' borderWidth='2px' borderColor={'#48cae4'} bg='transparent' mt='25px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} borderColor={'#48cae4'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <FaCircle color='#48cae4' />
                                        <Heading size={'xs'} px='10px'>Profit/Loss Ratio</Heading>
                                    </Flex>
                                </Box>

                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} align={'center'}>
                                    <Flex direction={'column'} mb={{ base: '20px', md: '0px' }}>
                                        <Text>Ratio of AVG WIN vs AVG LOSS</Text>
                                        <Text mt='10px' fontWeight={700}>Score = actual as percentage of target</Text>
                                    </Flex>

                                    <Flex maxW={'200px'}>
                                        <InputGroup>
                                            <InputLeftAddon>Target</InputLeftAddon>
                                            <NumberInput allowMouseWheel min={1} step={0.1} value={plratio} onChange={(e) => updatevalue(Number(e), 1)}>
                                                <NumberInputField color={textColor} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </InputGroup>
                                    </Flex>
                                </Flex>
                            </Card>

                            <Card h='100%' borderWidth='2px' borderColor={'#0052e0'} bg='transparent' mt='25px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} borderColor={'#0052e0'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <FaCircle color='#0052e0' />
                                        <Heading size={'xs'} px='10px'>Win/Loss Ratio</Heading>
                                    </Flex>
                                </Box>
                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} align={'center'}>
                                    <Flex direction={'column'} mb={{ base: '20px', md: '0px' }}>
                                        <Text>Ratio of total WINNERS vs LOSERS</Text>
                                        <Text mt='10px' fontWeight={700}>Score = actual as percentage of target</Text>
                                    </Flex>
                                    <Flex maxW={'200px'}>
                                        <InputGroup>
                                            <InputLeftAddon>Target</InputLeftAddon>
                                            <NumberInput allowMouseWheel min={1} step={0.1} value={wlratio} onChange={(e) => updatevalue(Number(e), 2)}>
                                                <NumberInputField color={textColor} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </InputGroup>
                                    </Flex>
                                </Flex>
                            </Card>
                            <Card h='100%' borderWidth='2px' borderColor={'#ff8c00'} bg='transparent' mt='25px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} borderColor={'#ff8c00'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <FaCircle color='#ff8c00' />
                                        <Heading size={'xs'} px='10px'>R-Value (real vs target)</Heading>
                                    </Flex>
                                </Box>

                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} align={'center'}>
                                    <Flex direction={'row'} mb={{ base: '20px', md: '0px' }} align={'center'}>
                                        <Checkbox onChange={updatetargetchk} isChecked={targetchk}>Use trade targets</Checkbox>
                                    </Flex>
                                    <Flex maxW={'280px'}>
                                        <InputGroup>
                                            <InputLeftAddon>Default R-Value target</InputLeftAddon>
                                            <NumberInput allowMouseWheel min={1} step={0.1} value={rtarget} onChange={(e) => updatevalue(Number(e), 3)}>
                                                <NumberInputField color={textColor} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </InputGroup>
                                    </Flex>
                                </Flex>
                                <Flex direction={'column'} mt='20px'>
                                    <Text fontWeight={700}>Score = (R-Value (real) / R-Value (target)) * 100</Text>
                                </Flex>
                            </Card>
                            <Card h='100%' borderWidth='2px' borderColor={'#5bce68'} bg='transparent' mt='25px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} borderColor={'#5bce68'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <FaCircle color='#5bce68' />
                                        <Heading size={'xs'} px='10px'>Win %</Heading>
                                    </Flex>
                                </Box>

                                <Text>Percentage of WINNING trades</Text>
                            </Card>
                            <Card h='100%' borderWidth='2px' borderColor={'#ff0000'} bg='transparent' mt='25px'>
                                <Box mt='-35px' mb='10px' bg={headcol} borderWidth={'2px'} borderColor={'#ff0000'} maxW={'220px'} px='10px' py='5px' borderRadius={'5px'}>
                                    <Flex direction={'row'}>
                                        <FaCircle color='#ff0000' />
                                        <Heading size={'xs'} px='10px'>Drawdown SCORE</Heading>
                                    </Flex>
                                </Box>
                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'}>
                                    <Text fontWeight={700}>Score = 100 - drawdown (max)</Text>
                                    <Text fontWeight={700}>(lower drawdown = higher score)</Text>
                                </Flex>
                            </Card>

                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={'ghost'} mr={3} onClick={resetdefault}>
                            Reset defaults
                        </Button>
                        <Button variant={'outline'} colorScheme='blue' onClick={updateTargets}>Update</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default JornoScoreSettings