import Dexie from "dexie";
import {
  JUser, JUserSchema,
  Trade, TradeSchema,
  Instrument, IntrumentSchema,
  Statistics, StatisticsSchema,
  Account, AccountSchema,
  Strategy, StategySchema,
  Mistake, MistakeSchema,
  AccountGroup, AccountGroupSchema,
  NotesTemplate, NotesTemplateSchema,
  DayNote, DayNotesSchema,
  JournalEntry, JournalSchema,
  Mentor, MentorSchema,
  Mentee, MenteeSchema,
  MentorMessage, MentorMessageSchema,
  UserTradeLogic, UserTradeLogicSchema
} from "views/trader/trades/variables/types";

Dexie.debug = false;

export class JornoDb extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instantiated by Dexie in stores() method)
  users!: Dexie.Table<JUser, number>;
  trades!: Dexie.Table<Trade, number>; // number = type of the primkey
  instruments!: Dexie.Table<Instrument, number>;
  statistics!: Dexie.Table<Statistics, number>;
  accountgroups!: Dexie.Table<AccountGroup, number>;
  accounts!: Dexie.Table<Account, number>;
  strategies!: Dexie.Table<Strategy, number>;
  mistakes!: Dexie.Table<Mistake, number>;
  templates!: Dexie.Table<NotesTemplate, number>;
  daynotes!: Dexie.Table<DayNote, number>;
  journals!: Dexie.Table<JournalEntry, number>;
  mentors!: Dexie.Table<Mentor, number>;
  mentees!: Dexie.Table<Mentee, number>;
  mentormessages!: Dexie.Table<MentorMessage, number>;
  usertradelogic!: Dexie.Table<UserTradeLogic, number>;
  

  constructor() {
    super("JornoDb");
    this.version(2).stores({
      users: JUserSchema,
      trades: TradeSchema,
      instruments: IntrumentSchema,
      statistics: StatisticsSchema,
      accountgroups: AccountGroupSchema,
      accounts: AccountSchema,
      strategies: StategySchema,
      mistakes: MistakeSchema,
      templates: NotesTemplateSchema,
      daynotes: DayNotesSchema,
      journals: JournalSchema,
      mentors: MentorSchema,
      mentees: MenteeSchema,
      mentormessages: MentorMessageSchema,
      usertradelogic: UserTradeLogicSchema
    }).upgrade(tx => {
      return tx.table("trades").toCollection().modify(trade => {
        trade.target_price = 0;
        trade.target_rvalue = 0;
      });
    });
  }
}

export const db = new JornoDb();

