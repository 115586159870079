import { Box, Center, Divider, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import RuleScore from 'views/trader/trades/components/fields/RuleScore';
import { currencyformat } from 'views/trader/trades/variables/FormatData';
import { StatStrat } from 'views/trader/trades/variables/types';

const StratStats = (props: { loading: boolean, stats: StatStrat, currency: string }) => {

    const { loading, stats, currency } = props;

    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const bgcolor = useColorModeValue('white', 'navy.900');
    const barcolor = useColorModeValue('gray.300', 'gray.600');
    const divcolor = useColorModeValue('gray.500', 'white');
    const [wincol, setWincol] = useState('green.500');
    const [losscol, setLosscol] = useState('red.500');
    const [tradecnt, setTradecnt] = useState(1);


    useEffect(() => {
        let trades = stats.wcount + stats.lcount;
        setTradecnt(trades);
        setWincol(trades > 0 ? 'green.500' : barcolor)
        setLosscol(trades > 0 ? 'red.500' : barcolor)
    }, [stats, barcolor])

    const getwidth = (winval: number, lossval: number) => {
        let total = winval + Math.abs(lossval);
        let winp = total > 0 ? (winval / total) * 100 : 0;
        let losp = total > 0 ? (Math.abs(lossval) / total) * 100 : 0;

        let winw = total > 0 ? winp.toFixed() + '%' : '50%'
        let losw = total > 0 ? losp.toFixed() + '%' : '50%'

        return [winw, losw];
    }

    return (
        <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' mt='15px'>
            <Flex direction={'row'} justify={'space-between'}>
                <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Performance</Heading>
                <Heading size={'xs'} mt='-30px' bg={bgcolor} color={'red.500'} px='10px' hidden={tradecnt>0}>No tagged trades found</Heading>
            </Flex>

            <Box h={'100%'} justifyContent={'center'} alignContent={'center'} hidden={!loading}>
                <Flex justify={'center'}>
                    <Spinner color={spinnerColor}></Spinner>
                </Flex>
            </Box>

            <Center>
                <Flex w={'100%'} h='70%' position={'absolute'} justify={'center'} mt='-35px'>
                    <Divider orientation='vertical' borderWidth={'2px'} borderColor={divcolor} variant='dashed' />
                </Flex>
                <Flex w={'100%'} direction={'column'} >

                    <Flex direction={'column'} mt='10px'>
                        <Flex direction={'row'} justify={'space-between'}>
                            <Flex direction={'column'}>
                                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Winners</Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Losers</Text>
                            </Flex>
                        </Flex>
                        <Flex direction={'row'} w='100%' gap={1}>
                            <Box w={getwidth(stats.wcount, stats.lcount)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'start'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{stats.wcount > 0 ? stats.wcount + " (" + stats.wperc.toFixed() + "%)" : 0}</Text>
                                </Flex>
                            </Box>
                            <Box w={getwidth(stats.wcount, stats.lcount)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'end'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{stats.lcount > 0 ? stats.lcount + " (" + stats.lperc.toFixed() + "%)" : 0}</Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Flex direction={'column'} mt='10px'>
                        <Flex direction={'row'} justify={'space-between'}>
                            <Flex direction={'column'}>
                                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>AVG Win</Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>AVG Loss</Text>
                            </Flex>
                        </Flex>
                        <Flex direction={'row'} w='100%' gap={1}>
                            <Box w={getwidth(stats.wavg, stats.lavg)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'start'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{currencyformat(stats.wavg, currency)}</Text>
                                </Flex>
                            </Box>
                            <Box w={getwidth(stats.wavg, stats.lavg)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'end'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{currencyformat(stats.lavg, currency)}</Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Flex direction={'column'} mt='10px'>
                        <Flex direction={'row'} justify={'space-between'}>
                            <Flex direction={'column'}>
                                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Biggest Win</Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Biggest Loss</Text>
                            </Flex>
                        </Flex>
                        <Flex direction={'row'} w='100%' gap={1}>
                            <Box w={getwidth(stats.wmax, stats.lmax)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'start'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{currencyformat(stats.wmax, currency)}</Text>
                                </Flex>
                            </Box>
                            <Box w={getwidth(stats.wmax, stats.lmax)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'end'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{currencyformat(stats.lmax, currency)}</Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Flex direction={'column'} mt='10px'>
                        <Flex direction={'row'} justify={'space-between'}>
                            <Flex direction={'column'}>
                                <Text color={wincol} fontSize="sm" fontWeight="600" mb='3px' ms='20px'>Win total</Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <Text color={losscol} fontSize="sm" fontWeight="600" mb='3px' me='20px'>Loss total</Text>
                            </Flex>
                        </Flex>
                        <Flex direction={'row'} w='100%' gap={1}>
                            <Box w={getwidth(stats.wtotal, stats.ltotal)[0]} h='25px' bg={wincol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'start'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" ms='20px'>{currencyformat(stats.wtotal, currency)}</Text>
                                </Flex>
                            </Box>
                            <Box w={getwidth(stats.wtotal, stats.ltotal)[1]} h='25px' bg={losscol} borderRadius={'10px'} alignContent={'center'}>
                                <Flex justify={'end'}>
                                    <Text color={'white'} fontSize="sm" fontWeight="700" me='20px'>{currencyformat(stats.ltotal, currency)}</Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Flex direction={'row'} justify={'space-between'} mt='20px'>
                        <Flex direction={'column'}>
                            <Text color={wincol} fontSize="sm" fontWeight="600" mb='10px'>AVG Rulescore</Text>
                            <RuleScore rulescore={stats.wavgscore} width={'100%'} size={'lg'} />
                        </Flex>
                        <Flex direction={'column'}>
                            <Text color={losscol} fontSize="sm" fontWeight="600" mb='10px'>AVG Rulescore</Text>
                            <RuleScore rulescore={stats.lavgscore} width={'100%'} size={'lg'} />
                        </Flex>
                    </Flex>

                </Flex>
            </Center>
        </Card>
    )
}

export default StratStats