import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, Flex, AlertDialogBody, Button, Text, useColorModeValue, Input, AlertDialogCloseButton } from "@chakra-ui/react";
import { useRef, useState } from "react";

export const useTradeLogicDialog = ({ }: any): [(category: string) => Promise<string>, () => JSX.Element] => {

    const [promise, setPromise] = useState<{ resolve: (value: string) => void } | null>(null);
    const cancelRef = useRef();
    const inputRef = useRef(null);

    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const [loading, setLoading] = useState(false);
    const [varTitle, setVartitle] = useState('');

    async function addVariable(category: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            switch (category) {
                case 'market_condition':
                    setVartitle("Market condition")
                    break;
                case 'key_level':
                    setVartitle("Key level")
                    break;
                case 'htf':
                    setVartitle("higher timeframe")
                    break;
                case 'htf_trend':
                    setVartitle("higher timeframe trend")
                    break;
                case 'etf':
                    setVartitle("entry timeframe")
                    break;
                case 'entry':
                    setVartitle("entry signal")
                    break;
                case 'stop':
                    setVartitle("stop logic")
                    break;
                case 'target':
                    setVartitle("target logic")
                    break;
            }
            setPromise({ resolve });
        });
    }

    const handleConfirm = async () => {
        setLoading(true);
        promise?.resolve(inputRef.current.value.trim());//commission);
        handleClose();
    }

    const handleCancel = () => {
        promise?.resolve(inputRef.current.value.trim());//commission);
        handleClose();
    }

    const handleClose = () => {
        setPromise(null);
        setLoading(false);
    }

    const TradeLogicDialog = () => {
        return (
            <>
                <AlertDialog leastDestructiveRef={cancelRef} isOpen={promise !== null} onClose={handleCancel} motionPreset='slideInBottom' closeOnOverlayClick={false}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogCloseButton />
                            <AlertDialogHeader fontSize='lg' >
                                <Flex direction={'row'} align={'center'} justify={'space-between'}>
                                    <Text mb='20px'>Add new ...</Text>
                                    <Text fontWeight={'700'} me='40px' mb='20px'>{varTitle.toUpperCase()}</Text>
                                </Flex>
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                <Flex justify='center'>
                                    <Input ref={inputRef} color={textColor} fontWeight={'500'} placeholder="Enter text ..." maxLength={30} autoFocus />
                                </Flex>
                                <Flex direction={'row'} justify={'end'} mt='30px' mb='20px'>
                                    <Button ref={cancelRef} onClick={handleConfirm} colorScheme='teal' ml={3} isDisabled={loading} isLoading={loading} loadingText="Processing">
                                        Accept
                                    </Button>
                                </Flex>
                            </AlertDialogBody>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        )
    };

    return [addVariable, TradeLogicDialog];
}