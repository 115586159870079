import dayjs from "dayjs";
import { useLiveQuery } from "dexie-react-hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "services/auth/AuthProvider";
import { db } from "services/db";
import { PeriodDateRange } from "views/trader/trades/variables/PeriodDateRange";
import { PeriodTradesArray } from "views/trader/trades/variables/PeriodTrades";
import { ProcessStats } from "views/trader/trades/variables/StatProcessor";
import { InstrumentsList } from 'views/trader/trades/variables/instruments';
import { Account, AccountGroup, Instrument, Mentee, Mentor, MentorMessage, Mistake, newAccountGroup, newInstrument, newStats, newUserTradeLogic, NotesTemplate, PeriodDates, PeriodTrades, Statistics, Strategy, Trade, UserTradeLogic } from "views/trader/trades/variables/types";

const DataContext = createContext({} as any);

export const DataProvider = (props: { children: any }) => {

    const { user, muuid } = useAuth();

    const [usertrades, setUserTrades] = useState<Trade[]>([]);
    const [dateformat, setDateFormat] = useState(localStorage.getItem("dateformat") ? localStorage.getItem("dateformat") : "YYYY-MM-DD HH:mm")
    const [tradesFilterGroupAccount, setTradesFilterGroupAccount] = useState<Trade[]>([]);
    const [periodtrades, setPeriodTrades] = useState<Trade[]>([]);
    const [tradesflagged, setTradesFlagged] = useState<Trade[]>([]);
    const [period, setPeriod] = useState('all');
    const [statistics, setStatistics] = useState<Statistics>(newStats());
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountgroups, setAccountgroups] = useState<AccountGroup[]>([]);
    const [instruments, setInstruments] = useState<Instrument[]>([]);
    const [userinstruments, setUserInstruments] = useState<Instrument[]>([]);
    const [journals, setJournals] = useState([]);
    const [groupAccounts, setGroupAccounts] = useState<Account[]>([]);
    const [defaultaccount, setDefaultAccount] = useState<Account>(null);
    const [defaultgroup, setDefaultGroup] = useState<AccountGroup>(newAccountGroup());
    const [defaultcurrency, setDefaultCurrency] = useState(localStorage.getItem("currency") ? localStorage.getItem("currency") : 'USD');
    const [strategies, setStrategies] = useState<Strategy[]>([]);
    const [mistakes, setMistakes] = useState<Mistake[]>([]);
    const [mentors, setMentors] = useState<Mentor[]>([]);
    const [mentees, setMentees] = useState<Mentee[]>([]);
    const [mentormessages, setmentormessages] = useState<MentorMessage[]>([]);
    const [usertemplates, setusertemplates] = useState<NotesTemplate[]>([]);
    const [usertradelogic, setusertradelogic] = useState<UserTradeLogic>();
    const [selectedCalendarDate, setselectedCalendarDate] = useState(dayjs());
    const [CalendarDateTrades, setCalendarDateTrades] = useState<Trade[]>([]);
    const [targets, SetTargets] = useState(localStorage.getItem('targets') ? JSON.parse(localStorage.getItem('targets')) : [2, 2, 2, 2]);
    const [perfchart, SetPerfchart] = useState(localStorage.getItem('perfchart') ? JSON.parse(localStorage.getItem('perfchart')) : 2);

    const [datasyncing, setDataSyncing] = useState(false);
    const [reloadData, setReloadData] = useState(false);

    const [usefees, setUsefees] = useState(() => {
        let up = localStorage.getItem("usefees");
        if (up) {
            return JSON.parse(up);
        }
        else {
            localStorage.setItem('usefees', JSON.stringify(true));
            return true;
        }

    });
    const [usestops, setUseStops] = useState(() => {
        let up = localStorage.getItem("usestops");
        if (up) {
            return JSON.parse(up);
        }
        else {
            localStorage.setItem('usestops', JSON.stringify(true));
            return true;
        }
    });



    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.trades.where({ userid: user.id }).toArray().then((tds) => {
                if (tds) {
                    tds.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()); // sort from most recent first
                    setUserTrades(tds);
                }
            }).catch(() => { });
        }
    }, [user, reloadData])

    const updatesyncing = (sync: boolean) => {
        setDataSyncing(sync);
        setTimeout(() => {
            if (!sync) {
                setReloadData(!reloadData);
            }
        }, 500);

    }

    useLiveQuery(async () => {
        if (!datasyncing) {
            await db.statistics.toArray().then((s) => {
                setStatistics(s.length > 0 ? s[0] : newStats());
                setPeriod(s.length ? s[0].period : 'all');
            })
        }

    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.strategies.where({ userid: user.id }).toArray().then((strats) => {
                setStrategies(strats);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.mistakes.where({ userid: user.id }).toArray().then((mists) => {
                setMistakes(mists);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.journals.where({ userid: user.id }).toArray((journals) => {
                return journals.map(journal => ({
                    id: journal.id,
                    date: journal.date,
                    title: journal.title,
                    category: journal.category,
                    flagged: journal.flagged,
                    published: false
                }));
            }).then(async (res) => {
                setJournals(res);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.usertradelogic.get(user.id).then((logic) => {
                if (logic) {
                    logic.last_uuid = muuid;
                    setusertradelogic(logic);
                }
                else {
                    let utl = newUserTradeLogic();
                    utl.userid = user.id;
                    utl.last_uuid = muuid;
                    setusertradelogic(utl);
                }

            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.templates.where({ userid: user.id }).toArray().then((temps) => {
                setusertemplates(temps);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.mentors.where({ userid: user.id }).toArray().then((m) => {
                setMentors(m);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.mentees.where({ userid: user.id }).toArray().then((m) => {
                setMentees(m);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (!datasyncing) {
            await db.mentormessages.orderBy('id').reverse().toArray().then((ms) => {
                setmentormessages(ms);
            })
        }

    }, [user, reloadData])

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.instruments.where({ userid: user.id }).toArray().then((ins) => {
                setUserInstruments(ins);
            }).catch(() => { })
        }
    }, [user, reloadData])

    useLiveQuery(async () => {
        if (!datasyncing) {
            await db.instruments.orderBy('relevance').reverse().toArray().then((insts) => {
                setInstruments(insts);
            }).catch(error => { })
        }
    }, [user, reloadData])

    useEffect(() => {
        db.instruments.count().then((c) => {
            if (c < 1) {
                addInstuments().then((instret) => {
                    setInstruments(instret)
                })
            }
        }).catch(error => { })

    }, [user])

    async function addInstuments(): Promise<Instrument[]> {
        return new Promise(async (resolve, reject) => {
            let insts: Instrument[] = [];
            for (var inst of InstrumentsList) {
                const newInst = newInstrument(inst);
                insts.push(newInst);
            }
            await db.instruments.bulkPut(insts).then(() => {
                resolve(insts);
            }).catch(() => {
                resolve(insts);
            })
        })
    }

    useLiveQuery(async () => {
        if (user && !datasyncing) {
            await db.accountgroups.where({ userid: user.id }).toArray().then(async (grps) => {
                if (grps) {
                    setAccountgroups(grps);
                    const grp = grps.filter((g) => g.defaultgrp);
                    setDefaultGroup(grp.length ? grp[0] : newAccountGroup());

                    if (grp.length) {
                        await db.accounts.where({ userid: user.id }).toArray().then((accs) => {
                            if (accs) {
                                setAccounts(accs);
                                let grpaccs = accs.filter((a) => a.groupid === grp[0].groupid);
                                setGroupAccounts(grpaccs);
                                let defacc = grpaccs.filter((a) => a.defaultacc);
                                setDefaultAccount(defacc.length ? defacc[0] : null);

                                setDefaultCurrency(defacc.length ? defacc[0].currency : 'USD')
                            }
                        })
                    }
                }
            }).catch(() => { });
        }
    }, [user, reloadData])

    useEffect(() => {
        if (!datasyncing) {
            if (defaultgroup) {
                setTradesFilterGroupAccount(defaultaccount ? usertrades.filter((t) => t.accountid === defaultaccount.accountid) : usertrades.filter((t) => t.groupid === defaultgroup.groupid));
            }
            else {
                setTradesFilterGroupAccount([]);
            }
        }
    }, [defaultaccount, defaultgroup, usertrades, reloadData])

    const updateFlaggedTrades = (flagtype: string) => {
        switch (flagtype) {
            case 'Reviewed':
                setTradesFlagged(tradesFilterGroupAccount.filter((tr) => tr.reviewed));
                break;
            case 'Flagged':
                setTradesFlagged(tradesFilterGroupAccount.filter((tr) => tr.flagged));
                break;
        }
    }

    useEffect(() => {
        let p: PeriodDates = PeriodDateRange(period);
        let t: PeriodTrades = PeriodTradesArray(tradesFilterGroupAccount, period, p);
        setPeriodTrades(t.current);
        updateStats();
    }, [period, tradesFilterGroupAccount]);

    useEffect(() => {
        let flag = localStorage.getItem('dashflag') ? localStorage.getItem('dashflag') : 'Flagged';
        updateFlaggedTrades(flag);
        updateStats();
    }, [tradesFilterGroupAccount]);

    useEffect(() => {
        let dt = tradesFilterGroupAccount.filter((t) => dayjs(t.datetime).isSame(selectedCalendarDate, 'day'));
        dt.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()); // sort from most recent first
        setCalendarDateTrades(dt);
    }, [selectedCalendarDate, tradesFilterGroupAccount])

    const updateStats = async () => {
        if (defaultaccount) {
            await ProcessStats(tradesFilterGroupAccount, period, defaultaccount.currency);
            localStorage.setItem("currency", defaultaccount.currency);
        }
        else if (defaultgroup) {
            await ProcessStats(tradesFilterGroupAccount, period, defaultgroup.currency);
            localStorage.setItem("currency", defaultgroup.currency);
        }
        else {
            let newStat = newStats();
            await db.statistics.put(newStat, newStat.id).catch(error => { });
        }
    }

    const updatedateformat = (dformat: string) => {
        setDateFormat(dformat);
        localStorage.setItem("dateformat", dformat);
    }
    const updateUsefees = (use: boolean) => {
        setUsefees(use);
        localStorage.setItem('usefees', JSON.stringify(use));
    }
    const updateUsestops = (use: boolean) => {
        setUseStops(use);
        localStorage.setItem('usestops', JSON.stringify(use));
    }

    const updatetargets = (targets: number[]) => {
        localStorage.setItem('targets', JSON.stringify(targets));
        SetTargets(targets);
    }
    const updatePerfchart = (type: number) => {
        localStorage.setItem('perfchart', JSON.stringify(type));
        SetPerfchart(type);
    }

    return (
        <DataContext.Provider value={{
            usertrades,
            usertemplates,
            usertradelogic,
            periodtrades,
            tradesflagged,
            statistics,
            accounts,
            defaultaccount,
            accountgroups,
            defaultgroup,
            groupAccounts,
            defaultcurrency,
            updateFlaggedTrades,
            period, setPeriod,
            dateformat, updatedateformat,
            instruments,
            userinstruments,
            journals,
            strategies,
            mistakes,
            mentors,
            mentees,
            mentormessages,
            CalendarDateTrades,
            setselectedCalendarDate,
            usefees, updateUsefees,
            usestops, updateUsestops,
            updatesyncing,
            targets, updatetargets,
            perfchart, updatePerfchart
        }}>
            {props.children}
        </DataContext.Provider>
    );

}

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error("useData error");
    }
    return context;
};