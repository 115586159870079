import Card from 'components/card/Card'
import { newTradeLogic, TradeLogic } from '../../variables/types'
import { Button, Flex, Input, useColorModeValue, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react';

const TradeLogicCard = (props: { tlogic: TradeLogic }) => {

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [logic, setLogic] = useState<TradeLogic>(newTradeLogic());

    useEffect(() => {
        setLogic(props.tlogic)
    }, [props.tlogic])

    return (
        <Card borderWidth={'1px'} maxW={'440px'} >
            <Flex direction={'column'} gap={3} justify={'center'}>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Market conditions</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.market_condition} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Recent key level</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.key_level} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Higher timeframe</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.htf} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>HTF trend</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.htf_trend} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Entry timeframe</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.etf} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Entry signal/level</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.entry} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Stop logic</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.stop} color={textColor} fontWeight={'500'}></Input>
                </Flex>
                <Flex direction={'row'}>
                    <Button w='220px' size={'sm'} variant={'outline'} borderRadius={'5px'} fontWeight={'500'} justifyContent={'start'} borderWidth={'2px'} color='gray'><Text color='gray'>Target logic</Text></Button>
                    <Input size={'sm'} variant={'flushed'} isReadOnly={true} value={"   " + logic.target} color={textColor} fontWeight={'500'}></Input>
                </Flex>
            </Flex>
        </Card>
    )
}

export default TradeLogicCard