import { Box, Flex, Heading, position, Progress, ProgressLabel, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react'
import { StatScore } from 'views/trader/trades/variables/types';
import JornoScoreSettings from '../modals/JornoScoreSettings';
import { useData } from 'services/data/DataProvider';

const JornoScoreChartPolar = (props: { scores: StatScore }) => {

    const { targets, periodtrades } = useData();

    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
    const bgcolor = useColorModeValue('white', 'navy.900');
    const progresstextColor = useColorModeValue('gray.900', 'gray.500');
    const progressBgColor = useColorModeValue('gray.100', 'gray.600');
    const gridcol = useColorModeValue('#e9e9e9', '#222222');
    const [gricol, setGricol] = useState('');
    const [score, setScore] = useState(0);
    const [scorecol, setScorecol] = useState('red');

    /*useEffect(() => {
        options.plotOptions.polarArea.rings.strokeColor = gridcol;
        options.plotOptions.polarArea.spokes.connectorColors = gridcol;
        options.legend.labels.colors = [textColorSecondary, textColorSecondary, textColorSecondary, textColorSecondary, textColorSecondary, textColorSecondary, textColorSecondary];
        setState({
            ...state,
            options: options
        })
    }, [gridcol])*/

    useEffect(() => {

        let c = localStorage.getItem('chakra-ui-color-mode') === 'dark' ? '#222222' : '#e9e9e9'
        setGricol(c)
        options.plotOptions.polarArea.rings.strokeColor = c;
        options.plotOptions.polarArea.spokes.connectorColors = c;

        if (targets.length) {
            let rcalc = 0;
            if (props.scores.avg_R_real > 0) {
                if (props.scores.avg_R_target > 0) {
                    rcalc = (props.scores.avg_R_real / props.scores.avg_R_target) * 100;
                }
                else {
                    rcalc = (props.scores.avg_R_real / targets[3]) * 100;
                }
            }

            let pf = (props.scores.profit_factor / targets[0]) * 100;
            let pl = (props.scores.pl_ratio / targets[1]) * 100;
            let wl = (props.scores.wlratio / targets[2]) * 100

            let score = [
                Number(pf > 100 ? 100 : pf.toFixed()),
                Number(pl > 100 ? 100 : pl.toFixed()),
                Number(props.scores.winperc.toFixed()),
                Number(wl > 100 ? 100 : wl.toFixed()),
                Number((100 - props.scores.drawdown_max).toFixed()),
                Number(rcalc > 100 ? 100 : rcalc.toFixed()),
            ]

            let scoreempty = (score[0] + score[1] + score[2] + score[3] + score[5]) === 0;
            if (scoreempty) {
                score[4] = 0;
            }

            let scoretotal = !scoreempty ? (score[0] + score[1] + score[2] + score[3] + score[4] + score[5]) / 6 : 0;

            setScore(scoretotal);
            setScorecol(scoretotal > 80 ? 'blue' : (scoretotal > 50 ? 'green' : (scoretotal > 25 ? 'orange' : 'red')));

            setState({
                ...state,
                series: { data: score },
                options: options
            })
        }


    }, [props.scores, targets, gridcol])

    let options = {
        chart: {
            toolbar: {
                show: false
            },
            offsetY: 60
        },

        labels: ['P-Factor', 'P/L Ratio', 'Win %', 'W/L Ratio', 'Drawdown', 'R-Value'],
        plotOptions: {
            polarArea: {
                rings: {
                    strokeWidth: 1,
                    strokeColor: gricol,
                },
                spokes: {
                    strokeWidth: 1,
                    connectorColors: gricol,
                }
            }
        },
        colors: ['#8f00ff', '#48cae4', '#5bce68', '#0052e0', '#cd001a', '#ff8c00'],
        legend: {
            show: false,
        },
        tooltip: {
            theme: 'dark',

            x: {
                show: true
            },
            y: {
                title: {
                    formatter: (seriesName: any) => seriesName + " score: ",
                }
            }
        }

    }

    const [state, setState] = useState({
        series: {
            data: [0, 0, 0, 0, 0, 0],
        },
        options: options,
    });

    return (
        <>
            <Card h='100%' borderWidth='1px' bg='transparent' mt='10px'>
                <Flex direction={'row'} justify={'space-between'}>
                    <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Performance score</Heading>
                    <JornoScoreSettings isbutton={false} />
                </Flex>

                {periodtrades.length < 1 ? (
                    <Flex justify={'center'} align={'center'} >
                        <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                            <Box p='10px' color={textColorSecondary} >
                                <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                            </Box>
                        </ScaleFade>
                    </Flex>
                ) : (
                    <Flex direction={'column'} h='100%' w='100%' align={'center'} mt='-30px' justify={'space-between'}>
                        <Box w='100%' h='80%'>
                            <Chart
                                type='polarArea'
                                series={state.series.data}
                                options={state.options}
                                width={'100%'}
                                height={'100%'}
                            />
                        </Box>
                        <Flex w={'100%'} direction={'row'} justify={'space-between'} align={'center'} mt='80px'>
                            <Text color={textColorSecondary} fontWeight={'500'} me='20px'>Score:</Text>
                            <Progress bg={progressBgColor} value={score} size='lg' colorScheme={scorecol} w={'100%'}>
                                <ProgressLabel color={progresstextColor} fontSize={'11px'}>{score.toFixed()}</ProgressLabel>
                            </Progress>
                        </Flex>
                    </Flex>
                )}
            </Card>
        </>
    )
}

export default JornoScoreChartPolar

/*

plotOptions: {
            polarArea: {
                rings: {
                    strokeWidth: 1,
                    strokeColor: '#222222',
                },
                spokes: {
                    strokeWidth: 1,
                    connectorColors: '#222222',
                }
            }
        },

*/