import { Button, Icon } from '@chakra-ui/react'
import { TbUserBolt } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom';
import { updateDefaultAccount, updateDefaultGroup } from 'services/apiservice';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';
import { useLoading } from 'services/loading/LoadingProvider';

const AccountSelect = (props: { accountid: number }) => {

    const { user, muuid } = useAuth();

    const navigate = useNavigate();
    const { setLoading } = useLoading();

    const selectaccount = async () => {

        setLoading(true);

        navigate("/trader/dashboard");

        await db.accounts.get(props.accountid).then(async (acc) => {

            await updateDefaultAccount(acc.accountid, acc.groupid, user.id, muuid).then(async () => {
                await updateDefaultGroup(acc.groupid, user.id, muuid).then(async () => {
                    setLoading(false);
                }).catch((e) => {
                    setLoading(false);
                })
            }).catch((e) => {
                setLoading(false);
            })
        })
    }

    return (
        <Button size={'sm'} onClick={selectaccount} colorScheme={'blue'} variant={'outline'} >
            <Icon as={TbUserBolt} boxSize={5} me='5px' />Select
        </Button>
    )
}

export default AccountSelect