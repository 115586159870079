import { Button, Text, Flex, FormControl, FormLabel, Icon, Switch, useColorModeValue, Spinner, InputGroup, Select, InputLeftAddon, InputRightAddon, useToast } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import dayjs from 'dayjs';
import Card from 'components/card/Card';
import { MdCalendarMonth, MdChevronLeft, MdChevronRight, MdOutlineQueryStats, MdOutlineTipsAndUpdates } from 'react-icons/md';
import { BsJournalText } from "react-icons/bs";

import "./journalcalendar.css"
import { db } from 'services/db';
import { newJournalEvent } from 'views/trader/trades/variables/types';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { TbUsers } from 'react-icons/tb';
import { IoHelpCircleOutline } from 'react-icons/io5';

const JournalCalendar = (props: { newjournalentry: any, openjournal: any, userJournals: any[], mentorJournals: any[], loading: boolean, loadingid: number, updatecategory: any }) => {

    const { newjournalentry, openjournal, userJournals, mentorJournals, loading, loadingid } = props;

    const { allowjournaladd, user } = useAuth();//useContext(AuthContext);
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const calRef = useRef(null);
    const [calTitle, setCalTitle] = useState(dayjs().format("MMMM YYYY"));
    const [journalevts, setjournalevts] = useState([]);
    const [showweekends, setshowweekends] = useState(localStorage.getItem("jweekends") ? JSON.parse(localStorage.getItem("jweekends")) : true);
    const [journalcategory, setjournalcategory] = useState("");
    const toast = useToast();
    const acctoast = "acctoast";
    const [mentordropwarn, setdropwarn] = useState(false);



    useEffect(() => {
        let journals = [...userJournals, ...mentorJournals];
        updateEvents(journals, journalcategory);
    }, [userJournals, mentorJournals])

    const updateEvents = (journals: any[], category: string) => {

        //if (!journals.length) {
        try { // need catch incase getApi() null
            //remove existing events from calendar
            let evts = calRef.current.getApi().getEvents();
            for (let ev of evts) {
                ev.remove();
            }
        }
        catch (e) { }
        //}

        journalevts.splice(0, journalevts.length);

        let arr = [];
        for (let j of journals) {

            if (category === "" || j.category === category) {
                let je = newJournalEvent();
                je.title = j.title;
                je.start = dayjs(j.date).format("YYYY-MM-DD");
                je.end = dayjs(j.date).format("YYYY-MM-DD");
                je.borderColor = eventColor(j.category, j.published);
                je.backgroundColor = eventColor(j.category, j.published);
                je.extendedProps.journalid = j.id;
                je.extendedProps.category = j.category;
                je.extendedProps.published = j.published; // flag for fetched mentor journals
                arr.push(je);
            }
        }
        setjournalevts([...journalevts, ...arr]);

        if (!localStorage.getItem('initjournal')) {
            toast({ title: "Here's how to use the journal ...", description: <Text whiteSpace="pre-line">{"\n1)  Click on a date to create a new entry\n2)  Click on a journal entry to view/edit\n3)  Drag and drop an entry to move dates"}</Text>, status: 'info', position: 'top', duration: null, isClosable: true })
            localStorage.setItem('initjournal', 'true');
        }
    }
    function eventColor(category: string, published: boolean) {
        if (published) {
            return "transparent";
        }
        else {
            switch (category) {
                case 'general': return "#01B574";
                case 'education': return "#3965FF";
                case 'outlook': return "#ffa63f";
                case 'review': return "#EA4848";
            }
        }
    }
    function renderEventContent(ev: any) {
        return (
            <>
                <Button height={'auto'} bg={ev.event.backgroundColor} isLoading={loadingid === ev.event.extendedProps.journalid} loadingText={'Fetching'} w='100%' borderColor={eventColor(ev.event.extendedProps.category, false)} borderWidth={'2px'} borderRadius={'5px'} h={'40px'} p={'10px'} onClick={() => openjournal(ev.event.extendedProps.journalid, ev.event.extendedProps.published)} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                    <Flex direction={'row'} justify={'start'}>
                        {renderIcon(ev.event.extendedProps.category, ev.event.extendedProps.published)}
                        <Text fontSize={{ base: 'xs', md: 'md' }} fontWeight={'700'} color={ev.event.extendedProps.published ? eventColor(ev.event.extendedProps.category, false) : 'white'}>{ev.event.title}</Text>
                    </Flex>
                </Button>
            </>
        )
    }
    
    function renderIcon(category: string, published: string) {
        if (published) {
            return <Icon as={TbUsers} boxSize={5} color={eventColor(category, false)} me={'10px'} />;
        }
        switch (category) {
            case 'general': return <Icon as={BsJournalText} boxSize={5} color={JSON.parse(published) ? eventColor(category, false) : 'white'} me={'10px'} />;
            case 'education': return <Icon as={MdOutlineTipsAndUpdates} boxSize={5} color={JSON.parse(published) ? eventColor(category, false) : 'white'} me={'10px'} />;
            case 'outlook': return <Icon as={MdCalendarMonth} boxSize={5} color={JSON.parse(published) ? eventColor(category, false) : 'white'} me={'10px'} />;
            case 'review': return <Icon as={MdOutlineQueryStats} boxSize={5} color={JSON.parse(published) ? eventColor(category, false) : 'white'} me={'10px'} />;
            default: return null;
        }
    }
    const updateWeekends = (e: ChangeEvent<HTMLInputElement>) => {
        setshowweekends(e.target.checked);
        localStorage.setItem('jweekends', JSON.stringify(e.target.checked));
    }
    const calforward = () => {
        calRef.current.getApi().next();
        setCalTitle(calRef.current.getApi().view.title);
    }
    const calback = () => {
        calRef.current.getApi().prev();
        setCalTitle(calRef.current.getApi().view.title);
    }
    const lastEntry = () => {
        try {
            let evts = calRef.current.getApi().getEvents();
            calRef.current.getApi().gotoDate(evts[evts.length - 1].start);
        }
        catch (err) {
            calRef.current.getApi().today();
        }
        setCalTitle(calRef.current.getApi().view.title);
    }
    const dateclicked = (day: any) => {
        newjournalentry(dayjs(day.date).format("YYYY-MM-DD"));
    }
    const dropEvent = async (evt: any) => {

        if (user.role !== "MENTOR" && evt.event.extendedProps.published === true) {
            evt.revert();
            if (!mentordropwarn) {
                toast({ id: acctoast, title: 'You cannot move mentor journals', description: "", status: 'warning', position: 'top', duration: 2000, isClosable: false });
                setdropwarn(true);
            }
            return;
        }

        let sameweek = dayjs(evt.event.start).isSame(evt.oldEvent.start, 'week');

        allowjournaladd(evt.event.start).then(async (r: boolean) => {
            if (r || sameweek) {
                let journal = { id: evt.event.extendedProps.journalid, date: dayjs(evt.event.start).format("YYYY-MM-DD") }
                await api.post('/journals/movejournal', { journal: journal }).then(async (res) => {
                    if (res.data === true) {
                        db.journals.update(evt.event.extendedProps.journalid, { date: dayjs(evt.event.start).format("YYYY-MM-DD"), sync_due: false }).catch((e) => { })
                    }
                    else {
                        db.journals.update(evt.event.extendedProps.journalid, { date: dayjs(evt.event.start).format("YYYY-MM-DD"), sync_due: true })
                    }
                }).catch((err) => {
                    db.journals.update(evt.event.extendedProps.journalid, { date: dayjs(evt.event.start).format("YYYY-MM-DD"), sync_due: true })
                })
            }
            else {
                if (!toast.isActive(acctoast)) {
                    toast({ id: acctoast, title: 'Maximum weekly entries reached ...', description: "Upgrade your subscription to allow more journal entries per week", status: 'error', position: 'top', duration: 3000, isClosable: true });
                }
                evt.revert();
            }
        })
    }

    const updatecategory = (e: ChangeEvent<HTMLSelectElement>) => {
        setjournalcategory(e.target.value);
        updateEvents(userJournals, e.target.value);
        props.updatecategory(e.target.value);
    }

    const showhelp = () => {
        toast({ title: "Here's how to use the journal ...", description: <Text whiteSpace="pre-line">{"\n1)  Click on a date to create a new entry\n2)  Click on a journal entry to view/edit\n3)  Drag and drop an entry to move dates"}</Text>, status: 'info', position: 'top', duration: null, isClosable: true })
    }

    return (
        <>
            <Card h='100%' minH={'1000px'} maxH={'1200px'} p='20px' borderColor={bdColor} borderWidth='0px' bg='transparent' >
                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} mb='20px'>
                    <Flex direction={'row'} mt='5px'>
                        <FormLabel fontWeight='bold' fontSize='md' mb='8px'>Journal entries</FormLabel>
                        <FormLabel fontWeight='normal' fontSize='md' mb='8px' color='secondaryGray.600' hidden={loading}>{calTitle}</FormLabel>
                        <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='md' ms='20px' hidden={!loading} />
                    </Flex>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                        <Flex direction={'row'} mb={{ base: '10px', md: '0px' }} mt={{ base: '20px', md: '0px' }}>
                            <FormControl display='flex' alignItems='center' ms='10px' mb={{ base: '10px', md: '0px' }}>
                                <Switch id='weekends' onChange={updateWeekends} isChecked={showweekends} />
                                <FormLabel htmlFor='weekends' mb='0' ms='10px'>
                                    Weekends
                                </FormLabel>
                            </FormControl>
                            <Button variant={'outline'} size={'md'} borderRadius='5px' me='5px' onClick={lastEntry}>
                                Last
                            </Button>
                            <Button variant={'outline'} p='0px' size={'md'} borderRadius='5px' onClick={calback}>
                                <Icon as={MdChevronLeft} boxSize={5} />
                            </Button>
                            <Button variant={'outline'} p='0px' size={'md'} borderRadius='5px' ms='5px' onClick={calforward}>
                                <Icon as={MdChevronRight} boxSize={5} />
                            </Button>
                        </Flex>
                        <Flex>
                            <InputGroup mb={{ base: '20px', md: '0px' }} ms={{ base: '0px', md: '10px' }} >
                                <InputLeftAddon fontWeight={'700'}>Category</InputLeftAddon>
                                <Select value={journalcategory} onChange={updatecategory} borderLeftRadius={'0px'} borderRightRadius={journalcategory === "" ? '5px' : '0px'}>
                                    <optgroup label='Filter'>
                                        <option value="">Show all</option>
                                    </optgroup>
                                    <optgroup label='Categories'>
                                        <option value="general">General</option>
                                        <option value="education">Education</option>
                                        <option value="outlook">Outlook</option>
                                        <option value="review">Review</option>
                                    </optgroup>
                                </Select>
                                <InputRightAddon hidden={journalcategory === ""} bgColor={eventColor(journalcategory, false)}>{renderIcon(journalcategory, 'false')}</InputRightAddon>

                                <Button variant={'outline'} borderWidth={'1px'} onClick={showhelp} zIndex={10} ms='5px' >
                                    <Icon as={IoHelpCircleOutline} boxSize={6} />
                                </Button>
                            </InputGroup>
                        </Flex>
                    </Flex>
                </Flex>

                <FullCalendar
                    ref={calRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={false}
                    initialView='dayGridMonth' //dayGridYear
                    dayCellClassNames={(info) => {
                        if (info.date.getMonth() !== info.view.currentStart.getMonth()) {
                            return 'non-current-month'; // Add a custom class to non-current month days
                        }
                        return '';
                    }}
                    contentHeight={'100%'}
                    events={journalevts}
                    initialDate={dayjs().format('YYYY-MM-DD')}
                    editable={true}
                    height='100%'
                    fixedWeekCount={false}
                    weekends={showweekends}
                    weekNumbers={true}
                    eventContent={renderEventContent}
                    dateClick={dateclicked}
                    eventDrop={dropEvent}
                />

            </Card>
        </>

    )
}

export default JournalCalendar