import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Icon, Flex, FormLabel, useColorModeValue, Select, useToast, Text, Spinner, Badge } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { MdOutlineGeneratingTokens } from 'react-icons/md'
import TradeLogicDelete from './TradeLogicDelete'
import { useTradeLogicDialog } from '../../hooks/useTradeLogicDialog'
import { newTradeLogic, Trade, TradeLogic } from '../../variables/types'
import { useData } from 'services/data/DataProvider'
import { updateUserTradeLogic } from 'services/apiservice'
import { db } from 'services/db'
import { LuMousePointerClick } from 'react-icons/lu'
import { TradeLogicClear } from './TradeLogicClear'

const TradeLogicModal = (props: { trade: Trade, acceptLogic: any }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { trade, acceptLogic } = props;
    const [logic, setLogic] = useState<TradeLogic>(newTradeLogic());
    const { usertradelogic } = useData();
    const toast = useToast();

    const [addVariable, TradeLogicDialog] = useTradeLogicDialog({});
    const [loading, setLoading] = useState(false);
    const [addloading, setAddLoading] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const bgcolor = useColorModeValue('white', 'gray.700');
    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const btnvariant = useColorModeValue('solid', 'outline');

    const [markets, setmarkets] = useState([]);
    const [levels, setlevels] = useState([]);
    const [htfs, sethtfs] = useState([]);
    const [htftrends, sethtftrends] = useState([]);
    const [etfs, setetfs] = useState([]);
    const [entries, setentries] = useState([]);
    const [stops, setstops] = useState([]);
    const [targets, settargets] = useState([]);

    const [market, setmarket] = useState('');
    const [level, setlevel] = useState('');
    const [htf, sethtf] = useState('');
    const [htftrend, sethtftrend] = useState('');
    const [etf, setetf] = useState('');
    const [entry, setentry] = useState('');
    const [stop, setstop] = useState('');
    const [target, settarget] = useState('');

    const [category, setcategory] = useState('');

    const openModal = () => {

        if (trade.trade_logic && trade.trade_logic[0]) {
            setLogic(trade.trade_logic[0])
            updatelogic(trade.trade_logic[0]);
        }

        onOpen();
    }

    const updatelogic = (logic: TradeLogic) => {
        if (logic) {
            setmarket(logic.market_condition)
            setlevel(logic.key_level)
            sethtf(logic.htf)
            sethtftrend(logic.htf_trend)
            setetf(logic.etf)
            setentry(logic.entry)
            setstop(logic.stop)
            settarget(logic.target)
        }
    }

    useEffect(() => {
        setmarkets(usertradelogic.market_condition);
        setlevels(usertradelogic.key_level);
        sethtfs(usertradelogic.htf);
        sethtftrends(usertradelogic.htf_trend);
        setetfs(usertradelogic.etf);
        setentries(usertradelogic.entry);
        setstops(usertradelogic.stop);
        settargets(usertradelogic.target);
        updatelogic(logic);
    }, [usertradelogic])

    const updatevariable = async (category: string, val: string) => {

        setcategory(category);

        if (val === 'other') {
            let newvar = await addVariable(category);

            if (newvar !== '') {
                setAddLoading(true);

                let logic_copy = JSON.parse(JSON.stringify(usertradelogic))
                logic_copy[category].push(newvar)
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        updatevariables(category, newvar)
                    }
                })
            }
            else {
                updatevariables(category, '')
            }
        }
        else {
            updatevariables(category, val)
        }

        setcategory('');
    }

    const updatevariables = (category: string, val: string) => {
        switch (category) {
            case 'market_condition':
                logic.market_condition = (val);
                break;
            case 'key_level':
                logic.key_level = (val);
                break;
            case 'htf':
                logic.htf = (val);
                break;
            case 'htf_trend':
                logic.htf_trend = (val);
                break;
            case 'etf':
                logic.etf = (val);
                break;
            case 'entry':
                logic.entry = (val);
                break;
            case 'stop':
                logic.stop = (val);
                break;
            case 'target':
                logic.target = (val);
                break;
        }
        updatelogic(logic);
    }

    function saveupdatedlogic(logic: any) {

        return new Promise(async (resolve, reject) => {
            await updateUserTradeLogic(logic).then((res) => {
                if (res === false) {
                    toast({ title: 'Oops ... error', description: <Text whiteSpace="pre-line">{"Unable to update variables\nCheck your internet connection and try again"}</Text>, status: 'error', position: 'top', duration: 2000, isClosable: true })
                    resolve(false);
                }
                else {
                    delete res.last_uuid;
                    db.usertradelogic.put(res, res.userid).catch((e) => { })
                    resolve(true);
                }
            })
            setLoading(false);
            setAddLoading(false);
        })
    }

    const deleteUserEntry = async (category: string, val: string) => {
        setcategory(category);
        let logic_copy = JSON.parse(JSON.stringify(usertradelogic))//let logic_copy = { ...usertradelogic };
        setLoading(true);
        switch (category) {
            case 'market_condition':
                logic_copy.market_condition = logic_copy.market_condition.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.market_condition = '';
                    }
                })
                break;
            case 'key_level':
                logic_copy.key_level = logic_copy.key_level.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.key_level = '';
                    }
                })
                break;
            case 'htf':
                logic_copy.htf = logic_copy.htf.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.htf = '';
                    }
                })
                break;
            case 'htf_trend':
                logic_copy.htf_trend = logic_copy.htf_trend.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.htf_trend = '';
                    }
                })
                break;
            case 'etf':
                logic_copy.etf = logic_copy.etf.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.etf = '';
                    }
                })
                break;
            case 'entry':
                logic_copy.entry = logic_copy.entry.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.entry = '';
                    }
                })
                break;
            case 'stop':
                logic_copy.stop = logic_copy.stop.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.stop = '';
                    }
                })
                break;
            case 'target':
                logic_copy.target = logic_copy.target.filter((obj: string) => obj !== val);
                await saveupdatedlogic(logic_copy).then((r) => {
                    if (r === true) {
                        logic.target = '';
                    }
                })
                break;
        }

        setcategory('');
    }

    const updateLogic = () => {
        acceptLogic(logic)
        closeModal();
    }

    const closeModal = () => {
        clearVariables();
        onClose();
    }
    const clearVariables = () => {
        setmarket('')
        setlevel('')
        sethtf('')
        sethtftrend('')
        setetf('')
        setentry('')
        setstop('')
        settarget('')
    }
    const clearLogic = () => {
        logic.market_condition = '';
        logic.key_level = '';
        logic.htf = '';
        logic.htf_trend = '';
        logic.etf = '';
        logic.entry = '';
        logic.stop = '';
        logic.target = '';
        updateLogic();
    }

    return (
        <>
            <Button variant={btnvariant} colorScheme={'green'} size={'sm'} borderRadius={'5px'} p='0px' ms={'5px'} px='10px' borderWidth='1px' onClick={openModal}>
                <Icon as={LuMousePointerClick} color={'green.200'} boxSize={5} me='10px' /> Edit trade logic
            </Button>

            <Modal isOpen={isOpen} onClose={closeModal} size={{ base: 'full', md: 'xl' }} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Trade Logic</ModalHeader>
                    {/*<ModalCloseButton />*/}
                    <ModalBody>
                        <Flex justify={'center'}>
                            <FormLabel size={'sm'} mb='20px'>* leave field unselected or blank if not applicable</FormLabel>
                        </Flex>
                        <Card bg='transparent' borderWidth={'1px'}>
                            <Flex direction={'column'} gap={5}>
                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' bg={bgcolor} color={textColor} px='10px'>Market conditions</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={market === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>

                                        <Select variant={'flushed'} value={market} onChange={(e) => { updatevariable('market_condition', e.target.value) }} color={market === '' ? '' : 'blue.500'} fontWeight={market === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>
                                            {(markets.length > 0 || (!markets.includes(logic.market_condition) && logic.market_condition !== '')) &&
                                                <optgroup label='User'>

                                                    {markets.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!markets.includes(logic.market_condition) && logic.market_condition !== '') &&
                                                        <option key={logic.market_condition} value={logic.market_condition}>
                                                            {logic.market_condition}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }

                                            <optgroup label='Defaults'>
                                                <option value='Trending'>Trending</option>
                                                <option value='Ranging'>Ranging</option>
                                                <option value='Consolidating'>Consolidating</option>
                                                <option value='Retracement'>Retracement</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'market_condition'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'market_condition'} val={market} hidden={!markets.includes(market)} loading={loading && category === 'market_condition'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Recent key level</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={level === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={level} onChange={(e) => { updatevariable('key_level', e.target.value) }} color={level === '' ? '' : 'blue.500'} fontWeight={level === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>
                                            {(levels.length > 0 || (!levels.includes(logic.key_level) && logic.key_level !== '')) &&
                                                <optgroup label='User'>

                                                    {levels.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!levels.includes(logic.key_level) && logic.key_level !== '') &&
                                                        <option key={logic.key_level} value={logic.key_level}>
                                                            {logic.key_level}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }

                                            <optgroup label='Defaults'>
                                                <option value='Internal range liquidity'>Internal range liquidity</option>
                                                <option value='External range liquidity'>External range liquidity</option>
                                                <option value='New York high'>New York high</option>
                                                <option value='New York low'>New York low</option>
                                                <option value='London high'>London high</option>
                                                <option value='London low'>London low</option>
                                                <option value='Asia high'>Asia high</option>
                                                <option value='Asia low'>Asia low</option>
                                                <option value='Previous daily high'>Previous daily high</option>
                                                <option value='Previous daily low'>Previous daily low</option>
                                                <option value='Previous weekly high'>Previous weekly high</option>
                                                <option value='Previous weekly low'>Previous weekly low</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'key_level'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'key_level'} val={level} hidden={!levels.includes(level)} loading={loading && category === 'key_level'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Higher timeframe</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={htf === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={htf} onChange={(e) => { updatevariable('htf', e.target.value) }} color={htf === '' ? '' : 'blue.500'} fontWeight={htf === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>
                                            {(htfs.length > 0 || (!htfs.includes(logic.htf) && logic.htf !== '')) &&
                                                <optgroup label='User'>

                                                    {htfs.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!htfs.includes(logic.htf) && logic.htf !== '') &&
                                                        <option key={logic.htf} value={logic.htf}>
                                                            {logic.htf}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }
                                            <optgroup label='Defaults'>
                                                <option value='Weekly'>Weekly</option>
                                                <option value='Daily'>Daily</option>
                                                <option value='4 Hour'>4 Hour</option>
                                                <option value='1 Hour'>1 Hour</option>
                                                <option value='30 Minute'>30 Minute</option>
                                                <option value='15 Minute'>15 Minute</option>
                                                <option value='5 Minute'>5 Minute</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'htf'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'htf'} val={htf} hidden={!htfs.includes(htf)} loading={loading && category === 'htf'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Higher timeframe trend</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={htftrend === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={htftrend} onChange={(e) => { updatevariable('htf_trend', e.target.value) }} color={htftrend === '' ? '' : 'blue.500'} fontWeight={htftrend === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>

                                            {(htftrends.length > 0 || (!htftrends.includes(logic.htf_trend) && logic.htf_trend !== '')) &&
                                                <optgroup label='User'>
                                                    {htftrends.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!htftrends.includes(logic.htf_trend) && logic.htf_trend !== '') &&
                                                        <option key={logic.htf_trend} value={logic.htf_trend}>
                                                            {logic.htf_trend}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }

                                            <optgroup label='Defaults'>
                                                <option value='Bullish'>Bullish</option>
                                                <option value='Bearish'>Bearish</option>
                                                <option value='Neutral'>Neutral</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'htf_trend'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'htf_trend'} val={htftrend} hidden={!htftrends.includes(htftrend)} loading={loading && category === 'htf_trend'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Entry timeframe</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={etf === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={etf} onChange={(e) => { updatevariable('etf', e.target.value) }} color={etf === '' ? '' : 'blue.500'} fontWeight={etf === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>
                                            {(etfs.length > 0 || (!etfs.includes(logic.etf) && logic.etf !== '')) &&
                                                <optgroup label='User'>

                                                    {etfs.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!etfs.includes(logic.etf) && logic.etf !== '') &&
                                                        <option key={logic.etf} value={logic.etf}>
                                                            {logic.etf}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }

                                            <optgroup label='Defaults'>
                                                <option value='1 Minute'>1 Minute</option>
                                                <option value='2 Minute'>2 Minute</option>
                                                <option value='3 Minute'>3 Minute</option>
                                                <option value='4 Minute'>4 Minute</option>
                                                <option value='5 Minute'>5 Minute</option>
                                                <option value='15 Minute'>15 Minute</option>
                                                <option value='30 Minute'>30 Minute</option>
                                                <option value='1 Hour'>1 Hour</option>
                                                <option value='4 Hour'>4 Hour</option>
                                                <option value='Daily'>Daily</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'etf'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'etf'} val={etf} hidden={!etfs.includes(etf)} loading={loading && category === 'etf'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Entry signal/level</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={entry === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={entry} onChange={(e) => { updatevariable('entry', e.target.value) }} color={entry === '' ? '' : 'blue.500'} fontWeight={entry === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>

                                            {(entries.length > 0 || (!entries.includes(logic.entry) && logic.entry !== '')) &&
                                                <optgroup label='User'>
                                                    {entries.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!entries.includes(logic.entry) && logic.entry !== '') &&
                                                        <option key={logic.entry} value={logic.entry}>
                                                            {logic.entry}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }
                                            <optgroup label='Defaults'>
                                                <option value='FVG'>FVG</option>
                                                <option value='Orderblock'>Orderblock</option>
                                                <option value='Breaker'>Breaker</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'entry'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'entry'} val={entry} hidden={!entries.includes(entry)} loading={loading && category === 'entry'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Stop logic</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={stop === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={stop} onChange={(e) => { updatevariable('stop', e.target.value) }} color={stop === '' ? '' : 'blue.500'} fontWeight={stop === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>
                                            {(stops.length > 0 || (!stops.includes(logic.stop) && logic.stop !== '')) &&
                                                <optgroup label='User'>
                                                    {stops.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!stops.includes(logic.stop) && logic.stop !== '') &&
                                                        <option key={logic.stop} value={logic.stop}>
                                                            {logic.stop}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }

                                            <optgroup label='Defaults'>
                                                <option value='Default points'>Default points</option>
                                                <option value='Default R-Value'>Default R-Value</option>
                                                <option value='FVG inversion'>FVG inversion</option>
                                                <option value='OB violation'>OB violation</option>
                                                <option value='Breaker violation'>Breaker violation</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'stop'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'stop'} val={stop} hidden={!stops.includes(stop)} loading={loading && category === 'stop'} />
                                    </Flex>
                                </Card>

                                <Card bg='transparent' borderWidth={'1px'} >
                                    <Flex direction={'row'} mt='-32px' >
                                        <FormLabel fontWeight='bold' fontSize='md' mb='8px' bg={bgcolor} color={textColor} px='10px'>Target logic</FormLabel>
                                    </Flex>
                                    <Flex direction={'row'} align={'center'}>
                                        <Flex hidden={target === ''}>
                                            <Icon as={FaCheck} color={'green.500'} me='10px' />
                                        </Flex>
                                        <Select variant={'flushed'} value={target} onChange={(e) => { updatevariable('target', e.target.value) }} color={target === '' ? '' : 'blue.500'} fontWeight={target === '' ? '' : '700'}>
                                            <option value=''>Select ...</option>
                                            <option value='other'>ADD user variable ...</option>
                                            {(targets.length > 0 || (!targets.includes(logic.target) && logic.target !== '')) &&
                                                <optgroup label='User'>
                                                    {targets.map((val: string) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                    {(!targets.includes(logic.target) && logic.target !== '') &&
                                                        <option key={logic.target} value={logic.target}>
                                                            {logic.target}
                                                        </option>
                                                    }
                                                </optgroup>
                                            }
                                            <optgroup label='Defaults'>
                                                <option value='Default points'>Default points</option>
                                                <option value='Default R-Value'>Default R-Value</option>
                                                <option value='Internal range liquidity'>Internal range liquidity</option>
                                                <option value='External range liquidity'>External range liquidity</option>
                                                <option value='New York high'>New York high</option>
                                                <option value='New York low'>New York low</option>
                                                <option value='London high'>London high</option>
                                                <option value='London low'>London low</option>
                                                <option value='Asia high'>Asia high</option>
                                                <option value='Asia low'>Asia low</option>
                                                <option value='Previous daily high'>Previous daily high</option>
                                                <option value='Previous daily low'>Previous daily low</option>
                                                <option value='Previous weekly high'>Previous weekly high</option>
                                                <option value='Previous weekly low'>Previous weekly low</option>
                                            </optgroup>
                                        </Select>
                                        <Flex hidden={!addloading || category !== 'target'} ms='10px'>
                                            <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='sm' />
                                        </Flex>
                                        <TradeLogicDelete deletefunction={deleteUserEntry} category={'target'} val={target} hidden={!targets.includes(target)} loading={loading && category === 'target'} />
                                    </Flex>
                                </Card>

                            </Flex>
                        </Card>
                    </ModalBody>

                    <ModalFooter>
                        {/*<Button colorScheme='red' variant={'outline'} mr={3} onClick={clearLogic}>
                            Clear
                        </Button>*/}
                        <TradeLogicClear callbackfunction={clearLogic}/>
                        <Button colorScheme='blue' mr={3} onClick={updateLogic}>
                            Accept
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <TradeLogicDialog />
        </>
    )
}

export default TradeLogicModal