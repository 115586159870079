import { Flex, FormLabel, Switch } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import api from 'services/api';

const RequestReview = (props:{ tradeid: number, checked: boolean, disabled: boolean, updaterequest: any}) => {

    const setreview = async(e: ChangeEvent<HTMLInputElement>) =>{
        props.updaterequest(e.target.checked);
        await api.post('/trade/requestreview', { tradeid: props.tradeid, review: e.target.checked }).catch(()=>{})
    }

    return (
        <Flex direction={'row'} justify={'end'} align={'center'} mt={{base:'5px', md:'20px'}} me={{ base: '0px', md: '40px' }}>
            <FormLabel mt='7px'>Request mentor review</FormLabel>
            <Switch isChecked={props.checked} colorScheme={'green'} onChange={setreview} isDisabled={props.disabled}/>
        </Flex>
    )
}

export default RequestReview