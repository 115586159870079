import { processedTrade } from "./ExecutionsTrade";
import { AccountExecutions, Execution, newExecution, Trade } from "./types";


export function ProcessExecutions(accex: AccountExecutions): Promise<Trade[]> {

    return new Promise(async (resolve, reject) => {
        interface ticker {
            ticker: string;
            execarray: Execution[];
            contract: string;
        }

        const execsbyticker: ticker[] = [];
        const trades: Trade[] = [];

        try {
            // split tickers into own arrays

            for (let e of accex.executions) {
                const tick: ticker = execsbyticker.find((ticker) => (ticker.ticker === e.ticker && ticker.contract === e.contract));
                if (tick) {
                    tick.execarray.push(e);
                } else {
                    let t: ticker = { ticker: e.ticker, execarray: [], contract: e.contract };
                    t.execarray.push(e);
                    execsbyticker.push(t);
                }
            }

            for (let t of execsbyticker) {
                //sort oldest to newest
                t.execarray.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());

                let ex: Execution[][] = [];
                let open = 0;
                let side = '';

                for (let i = 0; i < t.execarray.length; i++) {
                    let exec: Execution = t.execarray[i];

                    if (open === 0) {
                        side = exec.side;
                        open += exec.size;
                        ex.push([]); //add empty array to end of executions array
                        ex[ex.length - 1].push(exec);
                    }
                    else {
                        if (exec.side === side) {
                            open += exec.size;
                            ex[ex.length - 1].push(exec);
                        }
                        
                        else {
                            let remaining = open - exec.size;

                            if (remaining === 0) {
                                ex[ex.length - 1].push(exec);
                                side = '';
                                open = 0;
                            }
                            else if (remaining > 0) {
                                ex[ex.length - 1].push(exec);
                                open -= exec.size;
                            }
                            else if (remaining < 0) {

                                let f = exec.fee / exec.size; ///get per unit fee - if fee 0 then calcs to 0

                                exec.size = open;
                                exec.fee = f * exec.size;
                                ex[ex.length - 1].push(exec);
                                ex.push([]);
                                open = 0;
                                side = '';

                                let exnew = newExecution();
                                exnew.datetime = exec.datetime;
                                exnew.instrument = exec.instrument;
                                exnew.ticker = exec.ticker;
                                exnew.contract = exec.contract;
                                exnew.side = exec.side;
                                exnew.size = Math.abs(remaining);
                                exnew.price = exec.price;
                                exnew.fee = f * exnew.size; /// split fees here
                                exnew.currency = exec.currency;

                                let ind = (i + 1);
                                t.execarray.splice(ind, 0, exnew);
                            }
                        }
                    }
                }

                for (let t of ex) {
                    await processedTrade(t).then((res) => {
                        res.brokerid = accex.account;
                        trades.push(res);
                    })
                        .catch((err)=>{alert("PROCESS: "+err)});
                }
            }

            resolve(trades);

        }
        catch (err) {
            reject(err);
        }

    });

}



